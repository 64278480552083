import * as React from 'react';
import { connect } from 'react-redux';
import logo from '../../assets/KM-Logo-Square-Color.png';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { userManager } from '../../store/configureStore';
import { User } from 'oidc-client';
import { AppState } from '../../store';

type LogoutProps = {
    user: User;
}

class LogoutPage extends React.Component<LogoutProps> {

    public componentDidMount() {
        // Ensure the local FS user is destroyed (if we were referred here externally)
        if(this.props.user) {
            console.debug("logout-remove-user")
            userManager.removeUser();
        }

        // Log out from the backend
        axios.post(
                './api/logout'
            )
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    public render() {

        return (
            <Container className="logout text-center mt-5">
                <Row className="mb-3">
                    <Col>
                        <img
                            src={logo}
                            className="logo-secondary"
                            alt="Konica Minolta"
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <h3>You have logged out.</h3>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <h5>To protect sensitive information, close your browser.</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <a href={`${window.location.protocol}//${window.location.host}/login`}>
                            <Button variant="success">Sign In</Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        );
    }

}

function mapStateToProps(state: AppState) {
    return {
        user: state.oidc.user,
    };
}

export default connect(
    mapStateToProps,   
)(LogoutPage as any);
