import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { RoleActionTypes, REQUEST_ROLE, RECEIVE_ROLE, REQUEST_ROLE_DETAILS, RECEIVE_ROLE_DETAILS, UNLOAD_ROLE_DETAILS, SUBMIT_CREATE_MEMBER, RECEIVE_CREATE_MEMBER_RESPONSE, RECEIVE_CREATE_MEMBER_RESPONSE_ERROR, DISMISS_MEMBER_CREATE_ERROR, REQUEST_MEMBER_SUMMARY, RECEIVE_MEMBER_SUMMARY, SUBMIT_DELETE_MEMBER, RECEIVE_DELETE_MEMBER_RESPONSE, RECEIVE_DELETE_MEMBER_RESPONSE_ERROR, DISMISS_MEMBER_DELETE_ERROR, UNLOAD_ROLE, UNLOAD_ROLE_MEMBER_SUMMARY } from '../types/actions';
import { RoleSummaryDto } from '../types/Role/RoleSummaryDto';
import { RoleDetailsDto } from '../types/Role/RoleDetailsDto';
import { CreateMember } from '../types/Role/CreateMember';
import { RoleMember } from '../types/Role/RoleMember';
import { DeleteMember } from '../types/Role/DeleteMember';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface RoleState {
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingDetails: boolean;
    isLoadedDetails: boolean;
    isLoadingMembers: boolean;
    isLoadedMembers: boolean;
    isCreatingMember: boolean;
    isCreatingMemberError: boolean;
    isDeletingMember: boolean;
    isDeletingMemberError: boolean;
    roleSummary: RoleSummaryDto[];
    roleDetails: RoleDetailsDto;
    roleMemberSummary: RoleMember[];
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestRoleSummary: (apiResourceId: string | undefined): AppThunkAction<RoleActionTypes> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        let endpoint = (apiResourceId === undefined) ? `./api/roles` : `./api/roles/api/${apiResourceId}`;

        if (appState 
            && appState.role !== undefined 
            && !appState.role.isLoading 
            && !appState.role.isLoaded) 
        {
            dispatch({ type: REQUEST_ROLE });

            axios
                .request<RoleSummaryDto[]>({
                    url: endpoint
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_ROLE, roleSummary: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },

    unloadRoleSummary: (): AppThunkAction<RoleActionTypes> => (dispatch) => {

        dispatch({ type: UNLOAD_ROLE });
    },

    requestRoleDetails: (id: string): AppThunkAction<RoleActionTypes> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState 
            && appState.role !== undefined 
            && !appState.role.isLoadingDetails 
            && !appState.role.isLoadedDetails) 
        {
            dispatch({ type: REQUEST_ROLE_DETAILS });

            axios
                .request<RoleDetailsDto>({
                    url: `./api/roles/${id}`
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_ROLE_DETAILS, roleDetails: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },

    unloadRoleDetails: (): AppThunkAction<RoleActionTypes> => (dispatch) => {

        dispatch({ type: UNLOAD_ROLE_DETAILS });
    },

    requestRoleMemberSummary: (id: string): AppThunkAction<RoleActionTypes> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState 
            && appState.role !== undefined 
            && !appState.role.isLoadingMembers 
            && !appState.role.isLoadedMembers) 
        {
            dispatch({ type: REQUEST_MEMBER_SUMMARY });

            axios
                .request<RoleMember[]>({
                    url: `./api/roles/${id}/members`
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_MEMBER_SUMMARY, roleMemberSummary: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },

    unloadRoleMemberSummary: (): AppThunkAction<RoleActionTypes> => (dispatch) => {

        dispatch({ type: UNLOAD_ROLE_MEMBER_SUMMARY });
    },

    submitMember: (id: string, member: CreateMember): AppThunkAction<RoleActionTypes> => (dispatch, getState) => {

        const appState = getState();

        if (appState 
            && appState.role !== undefined 
            && !appState.role.isCreatingMember)
        {
            dispatch({ type: SUBMIT_CREATE_MEMBER });

            axios
                .post(
                    `./api/roles/${id}/members`, 
                    member
                )
                .then((response) => {
                    dispatch({ type: RECEIVE_CREATE_MEMBER_RESPONSE });
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: RECEIVE_CREATE_MEMBER_RESPONSE_ERROR });
                });
        }
    },
    
    dismissMemberCreateError: (): AppThunkAction<RoleActionTypes> => (dispatch) => {

        dispatch({ type: DISMISS_MEMBER_CREATE_ERROR });
    },

    submitDeleteMember: (id: string, member: DeleteMember): AppThunkAction<RoleActionTypes> => (dispatch, getState) => {

        const appState = getState();

        if (appState 
            && appState.role !== undefined 
            && !appState.role.isDeletingMember)
        {
            dispatch({ type: SUBMIT_DELETE_MEMBER });

            axios
                .delete(
                    `./api/roles/${id}/members`, 
                    { 
                        data: member 
                    }
                )
                .then((response) => {
                    dispatch({ type: RECEIVE_DELETE_MEMBER_RESPONSE });
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: RECEIVE_DELETE_MEMBER_RESPONSE_ERROR });
                });
        }
    },

    dismissMemberDeleteError: (): AppThunkAction<RoleActionTypes> => (dispatch) => {

        dispatch({ type: DISMISS_MEMBER_DELETE_ERROR });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const emptyDetails : RoleDetailsDto = {
    id: '',
    apiResourceId: '',
    displayName: '',
    description: '',
    createdDate: '',
};

export const reducer: Reducer<RoleState> = (state: RoleState | undefined, incomingAction: Action): RoleState => {
    if (state === undefined) {
        return {
            isLoading: false,
            isLoaded: false,
            isLoadingDetails: false,
            isLoadedDetails: false,
            isLoadingMembers: false,
            isLoadedMembers: false,
            isCreatingMember: false,
            isCreatingMemberError: false,
            isDeletingMember: false,
            isDeletingMemberError: false,
            roleSummary: [],
            roleDetails: emptyDetails,
            roleMemberSummary: [],
        };
    }

    const action = incomingAction as RoleActionTypes;
    switch (action.type) {
        case REQUEST_ROLE:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        case RECEIVE_ROLE:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                roleSummary: action.roleSummary,
            };
        case UNLOAD_ROLE:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                roleSummary: [],
            };
        case REQUEST_ROLE_DETAILS:
            return {
                ...state,
                isLoadingDetails: true,
                isLoadedDetails: false,
            };
        case RECEIVE_ROLE_DETAILS:
            return {
                ...state,
                isLoadingDetails: false,
                isLoadedDetails: true,
                roleDetails: action.roleDetails,
            };
        case UNLOAD_ROLE_DETAILS:
            return {
                ...state,
                isLoadingDetails: false,
                isLoadedDetails: false,
                roleDetails: emptyDetails
            };
        case REQUEST_MEMBER_SUMMARY:
            return {
                ...state,
                isLoadingMembers: true,
                isLoadedMembers: false,
            };
        case RECEIVE_MEMBER_SUMMARY:
            return {
                ...state,
                isLoadingMembers: false,
                isLoadedMembers: true,
                roleMemberSummary: action.roleMemberSummary,
            };
        case UNLOAD_ROLE_MEMBER_SUMMARY:
            return {
                ...state,
                isLoadingMembers: false,
                isLoadedMembers: false,
                roleMemberSummary: [],
            };
        case SUBMIT_CREATE_MEMBER:
            return {
                ...state,
                isCreatingMember: true,
            };
        case RECEIVE_CREATE_MEMBER_RESPONSE:
            return {
                ...state,
                isLoadedMembers: false,
                isCreatingMember: false,
            };
        case RECEIVE_CREATE_MEMBER_RESPONSE_ERROR:
            return {
                ...state,
                isCreatingMember: false,
                isCreatingMemberError: true,
            };
        case DISMISS_MEMBER_CREATE_ERROR:
            return {
                ...state,
                isCreatingMemberError: false,
            };
        case SUBMIT_DELETE_MEMBER:
            return {
                ...state,
                isDeletingMember: true,
            };
        case RECEIVE_DELETE_MEMBER_RESPONSE:
            return {
                ...state,
                isLoadedMembers: false,
                isDeletingMember: false,
            };
        case RECEIVE_DELETE_MEMBER_RESPONSE_ERROR:
            return {
                ...state,
                isDeletingMember: false,
                isDeletingMemberError: true,
            };
        case DISMISS_MEMBER_DELETE_ERROR:
            return {
                ...state,
                isDeletingMemberError: false,
            };
    }

    return state;
};
