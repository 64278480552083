import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as ApiStore from '../../store/Api';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import moment from 'moment-timezone'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { ApiResource } from '../../types/Api/ApiResource';

type ApiTableProps = {
    apiState: ApiStore.ApiState;
    actions: typeof ApiStore.actionCreators;
}

class ApiTable extends React.Component<ApiTableProps> {
    public constructor(props: ApiTableProps) {
        super(props);
    }

    _pageSize = 50;
    _timeZone: string = "Universal";

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.actions.requestApiSummary();
    }

    render() {
        
        const NoDataComponent: React.FunctionComponent = (): React.ReactElement => {
            if(this.props.apiState.isLoaded){
                return (
                    <div className="d-flex justify-content-center mt-3" data-cy="bootstrap-table-loaded">
                        <p className="text-center">No API's were found.</p>
                    </div>
                );
            }
            else{
                return (
                    <div className="d-flex justify-content-center mt-3">
                        <Spinner animation="border" variant="primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                );
            }
        };

        const { SearchBar } = Search;

        const columns = [
            {
                dataField: 'displayName',
                text: 'Name',
                sort: true,
                formatter: (displayName: string, row: ApiResource) => {
                    return (
                        <React.Fragment>
                            {displayName}<br/>
                            <small className="text-muted">{row.id}</small>
                        </React.Fragment>
                    )
                }
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true
            },
            {
                dataField: 'created',
                text: 'Created',
                sort: true,
                sortValue: (cell: any) => moment(cell).unix(),
                formatter: (cell: any) => {
                
                    let timestamp = moment(cell).tz(this._timeZone);

                    return (
                        <span>{timestamp.format("LLLL")}</span>
                    )
                }
            },
            {
                dataField: 'enabled',
                text: 'Enabled',
                sort: true,
                formatter: (enabled: boolean) => {
                    return (
                        <Form.Check 
                            disabled
                            type="switch"
                            label=""
                            checked={enabled}
                        />
                    )
                }
            },
            {
                dataField: 'id',
                text: '',
                sort: false,
                align: 'right',
                headerStyle: () => {
                    return { width: '80px'};
                },
                formatter: (id: number, row: ApiResource) => {
                    return (
                        <React.Fragment>
                            <Link className="text-dark" to={`/api/${id}`}>
                                <FontAwesomeIcon className="clickable mr-3" icon={faPencil} />
                            </Link>
                        </React.Fragment>
                    )
                }
            }
        ];

        return (
            <React.Fragment>
                <ToolkitProvider
                    keyField="id"
                    search
                    data={ this.props.apiState.apiResourceSummary } 
                    columns={ columns } 
                    >
                    {
                        props => (
                        <div>
                            <SearchBar { ...props.searchProps } />
                            <BootstrapTable
                                { ...props.baseProps }
                                striped
                                bordered={false}
                                defaultSorted={ [{ dataField: 'name', order: 'asc' }] }
                                pagination={ paginationFactory({sizePerPage: 25}) }
                                noDataIndication={ () => <NoDataComponent /> }
                            />
                        </div>
                        )
                    }
                </ToolkitProvider>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: AppState) {
    return {
        apiState: state.api
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, ApiStore.actionCreators), dispatch )
        }
    }
)(ApiTable as any);