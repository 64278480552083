import { User } from "oidc-client";
import * as React from "react";
import { Row, Col } from "react-bootstrap";

type DashboardHeaderComponentProps = {
    user: User;
}

class DashboardHeaderComponent extends React.Component<DashboardHeaderComponentProps> {
    public constructor(props: DashboardHeaderComponentProps) {
        super(props);
    }

    public render() {
        return (
            <Row>
                <Col md={12}>
                    <h1>Welcome, {this.props.user.profile.given_name}!</h1>
                    <p>
                        Welcome to the ATS Service Automation Framework. This modular collection of tools is designed to automate the delivery of 
                        Konica Minolta Managed IT solutions. Depending upon your user role, you may have access to one or more applications within
                        this framework. You can access the applications by clicking on the appropriate card below or using the navigation menu
                        at the top of the screen.
                    </p>
                    <p>
                        Please note that many of the applications contained within this infrastructure are currently in a <strong>beta state</strong>. 
                        Some rough edges are to be expected and new improvements are being made constantly. We welcome your feedback at any time.
                    </p>
                </Col>
            </Row>
        );
    }
}

export default DashboardHeaderComponent;