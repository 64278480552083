import * as React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import InputGroup from 'react-bootstrap/InputGroup'
import { RoleMember } from '../../types/Role/RoleMember';
import { QueryMember } from '../../types/Role/QueryMember';
import { RoleMemberType } from '../../types/Role/RoleMemberType';

interface RoleMemberAsyncTypeaheadProps {
    roleMemberSelectionCallback: (account: RoleMember | undefined) => void;
    memberType: RoleMemberType | undefined;
    isValid: boolean;
    isInvalid: boolean;
}

interface RoleMemberAsyncTypeaheadState {
    isLoading: boolean;
    query: string;
    roleMembers: RoleMember[];
    selectedRoleMember: RoleMember | undefined;
}

class RoleMemberAsyncTypeahead extends React.PureComponent<RoleMemberAsyncTypeaheadProps, RoleMemberAsyncTypeaheadState> {

    public constructor(props: RoleMemberAsyncTypeaheadProps) {
        super(props);
        this.state = {
            isLoading: false,
            query: '',
            roleMembers: [],
            selectedRoleMember: undefined
        }
    }
    
    render() {             
        return (
            <React.Fragment>
                <InputGroup className="mb-3">
                    <AsyncTypeahead<RoleMember>
                        id="role-member-search"
                        options={this.state.roleMembers}
                        isLoading={this.state.isLoading}
                        labelKey={option => `${option.displayName}`}
                        caseSensitive={false}
                        maxResults={50}
                        minLength={3}
                        delay={500}
                        isValid={this.props.isValid}
                        isInvalid={this.props.isInvalid}
                        onChange={this._handleChange}
                        onInputChange={this._handleInputChange}
                        onSearch={this._handleSearch}
                        selected={ this.state.selectedRoleMember === undefined ? [] : [this.state.selectedRoleMember]}
                        paginate
                        placeholder='Search by id, name, or email...'
                        searchText='Searching...'
                        paginationText='Display additional results...'
                    />
                </InputGroup>
            </React.Fragment>
        );
    }

    _handleClearInput = () => {

        this.setState({selectedRoleMember: undefined});
        this.props.roleMemberSelectionCallback(undefined);
    }

    _handleChange = (selected: RoleMember[]) => {
        this.setState({selectedRoleMember: selected[0]});
        this.props.roleMemberSelectionCallback(selected[0]);
    }

    _handleInputChange = (query: string) => {
        this.setState({query});
    }
    
    _handleSearch = (query: string) => {  
        this.setState({isLoading: true});
        this.makeAndHandleRequest({ type: this.props.memberType, query: query})
            .then((resp) => {
                this.setState({
                    isLoading: false,
                    roleMembers: resp
                });
        });
    }

    private async makeAndHandleRequest(query: QueryMember) {
        try {
            let response = await axios
                .post<RoleMember[]>(
                    "./api/roles/member/search",
                    query
                );
            const { data } = response;
            return data;
        }
        catch (error) {
            console.log(error);
            var emptySet: RoleMember[] = [];
            return emptySet;
        }
    }
};

export default RoleMemberAsyncTypeahead;