import * as React from "react";
import { Col, Card, Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { DashboardEntryDto } from "../../types/Dashboard/DashboardEntryDto";

type DashboardEntryComponentProps = {
    dashboardEntry: DashboardEntryDto;
}

class DashboardEntryComponent extends React.Component<DashboardEntryComponentProps> {
    public constructor(props: DashboardEntryComponentProps) {
        super(props);
    }


    public render() {
        return (
            <Col xl={4} lg={6} md={12} className="mb-4">
                <Card className="dashboardEntry mb-4">
                    <Card.Img variant="top" src={this.props.dashboardEntry.logoUri ?? "https://saus1cdn.blob.core.windows.net/client-logos/pexels-burak-kebapci-132340-w1920.jpg"} />
                    <Card.Body>
                        <Card.Title>{this.props.dashboardEntry.name}</Card.Title>
                        <Card.Text>
                            <ReactMarkdown 
                                children={this.props.dashboardEntry.description}
                                linkTarget="_blank"
                            />
                        </Card.Text>                        
                    </Card.Body>
                    <Card.Footer>
                        <Button href={this.props.dashboardEntry.clientUri} variant="primary">Go there!</Button>
                    </Card.Footer>
                </Card>
            </Col>
        );
    }
}

export default DashboardEntryComponent;