import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as ApiStore from '../../store/Api';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row';
import ApiTable from './ApiTable';

type ApiPageProps = {
    apiState: ApiStore.ApiState;
    actions: typeof ApiStore.actionCreators;
}

interface ApiPageState {
    isLoading: boolean;
}

class Api extends React.Component<ApiPageProps, ApiPageState> {

    constructor(props: ApiPageProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }
    
    public render() {

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Card className="panel">
                            <Card.Header>
                                <Row>
                                <Col md={12}>
                                    <h3>API</h3>
                                </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <ApiTable />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state: AppState) {
    return {
        apiState: state.api
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, ApiStore.actionCreators), dispatch )
        }
    }
)(Api as any);
