import * as Session from './Session';
import * as NavigationSummary from './NavigationSummary';
import * as Api from './Api';
import * as Role from './Role';
import * as Application from './Application';
import * as Dashboard from './Dashboard';
import { reducer as oidcReducer } from "redux-oidc";
import { CommonAppState, Navigation, versionSlice, errorSlice, notificationSlice } from '@konicaminolta/common-react';

// The top-level state object
export interface AppState extends CommonAppState {
    session: Session.SessionState;
    navigationSummary: NavigationSummary.NavigationSummaryState;
    api: Api.ApiState;
    role: Role.RoleState;
    application: Application.ApplicationState;
    dashboard: Dashboard.DashboardState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding AppState property type.
export const reducers = {
    oidc: oidcReducer,
    version: versionSlice.reducer,
    session: Session.reducer,
    navigation: Navigation.reducer,
    navigationSummary: NavigationSummary.reducer,
    api: Api.reducer,
    role: Role.reducer,
    application: Application.reducer,
    dashboard: Dashboard.reducer,
    errors: errorSlice.reducer,
    notification: notificationSlice.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => AppState): void;
}
