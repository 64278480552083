import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { ApiActionTypes, RECEIVE_API, REQUEST_API, REQUEST_API_DETAILS, RECEIVE_API_DETAILS, UNLOAD_API_DETAILS } from '../types/actions';
import { ApiResource } from '../types/Api/ApiResource';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ApiState {
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingDetails: boolean;
    isLoadedDetails: boolean;
    apiResourceSummary: ApiResource[];
    apiDetails: ApiResource;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestApiSummary: (): AppThunkAction<ApiActionTypes> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState 
            && appState.api !== undefined 
            && !appState.api.isLoading 
            && !appState.api.isLoaded) 
        {
            dispatch({ type: REQUEST_API });

            axios
                .request<ApiResource[]>({
                    url: './api/apis'
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_API, apiResourceSummary: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },

    requestApiDetails: (id: string): AppThunkAction<ApiActionTypes> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState 
            && appState.api !== undefined 
            && !appState.api.isLoadingDetails 
            && !appState.api.isLoadedDetails) 
        {
            dispatch({ type: REQUEST_API_DETAILS });

            axios
                .request<ApiResource>({
                    url: `./api/apis/${id}`
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_API_DETAILS, apiDetails: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },

    unloadApiDetails: (): AppThunkAction<ApiActionTypes> => (dispatch) => {

        dispatch({ type: UNLOAD_API_DETAILS });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const emptyDetails : ApiResource = {
    id: '',
    enabled: true,
    displayName: '',
    description: '',
    created: '',
};

export const reducer: Reducer<ApiState> = (state: ApiState | undefined, incomingAction: Action): ApiState => {
    if (state === undefined) {
        return {
            isLoading: false,
            isLoaded: false,
            isLoadingDetails: false,
            isLoadedDetails: false,
            apiResourceSummary: [],
            apiDetails: emptyDetails
        };
    }

    const action = incomingAction as ApiActionTypes;
    switch (action.type) {
        case REQUEST_API:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        case RECEIVE_API:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                apiResourceSummary: action.apiResourceSummary,
            };
        case REQUEST_API_DETAILS:
            return {
                ...state,
                isLoadingDetails: true,
                isLoadedDetails: false,
            };
        case RECEIVE_API_DETAILS:
            return {
                ...state,
                isLoadingDetails: false,
                isLoadedDetails: true,
                apiDetails: action.apiDetails,
            };
        case UNLOAD_API_DETAILS:
            return {
                ...state,
                isLoadingDetails: false,
                isLoadedDetails: false,
                apiDetails: emptyDetails
            };
    }

    return state;
};
