import { ISafTableColumnDef, ISafTableProps, SafTable } from '@konicaminolta/common-react';
import * as ApplicationStore from '../../store/Application';
import { Client } from '../../types/Application/Client';
import { useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Spinner, Stack } from 'react-bootstrap';
import { faCopy, faEye, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ClientPostLogoutRedirectUri } from '../../types/Application/ClientPostLogoutRedirectUri';
import { ClientRedirectUri } from '../../types/Application/ClientRedirectUri';
import Clipboard from 'react-clipboard.js';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface IApplicationTableProps {
    applicationState: ApplicationStore.ApplicationState;
    actions: typeof ApplicationStore.actionCreators;
}

export default function ApplicationTable(props: IApplicationTableProps) {

    const tableKey = "FS:ApplicationTable"
    const timeZone = "Universal";

    const [showEndpointModal, setShowEndpointModal] = useState<boolean>(false);

    useEffect(() => {
        props.actions.requestApplicationSummary()
    });

    const handleEndpointOpen = () => {
        setShowEndpointModal(true);
    }

    const handleEndpointClose = () => {
        setShowEndpointModal(false);
    }

    const getEndpointDetails = (client: Client) => {
        props.actions.unloadApplicationDetails();
        props.actions.requestApplicationDetails(`${client.clientId}`);
        handleEndpointOpen();
    }

    const columns: ISafTableColumnDef<Client>[] = [
        {
            id: 'clientName',
            accessorFn: (row: Client) => row,
            header: () => <>Application Name</>,
            cell: (row) => {

                const client = row.getValue() as Client;
                return (
                    <Stack gap={1}>
                        <div>{client.clientName}</div>
                        <div className='text-muted font-monospace'>
                            <small>{client.clientId}</small>
                        </div>
                    </Stack>
                )
            }
        },
        {
            id: 'created',
            accessorFn: (row: Client) => row,
            enableSorting: false,
            header: () => <>Created</>,
            cell: (row) => {

                const client = row.getValue() as Client;
                const timestamp = moment(client.created).tz(timeZone);

                return (<span>{timestamp.format("LLLL")}</span>)


            }

        },
        {
            id: 'clientId',
            accessorFn: (row: Client) => row,
            header: () => <>Enabled</>,
            enableSorting: false,
            cell: (row) => {

                const client = row.getValue() as Client;


                return client.enabled ?
                    <>True</> : <>False</>
            }
        },
        {
            id: 'clientId',
            accessorFn: (row: Client) => row,
            header: () => <></>,
            enableSorting: false,
            cell: (row) => {

                const client = row.getValue() as Client;

                return (
                    <div className='float-end'>
                        <Stack direction="horizontal" gap={3}>
                            <FontAwesomeIcon className="clickable text-dark mr-3" icon={faEye} onClick={() => getEndpointDetails(client)} />
                            <Link className="text-dark" to={`/applications/${client.clientId}`}>
                                <FontAwesomeIcon className="clickable mr-3" icon={faPencil} />
                            </Link>
                        </Stack>
                    </div>
                );
            }
        },

    ];

    const tableAttributes: ISafTableProps<Client> = {
        tableKey,
        bordered: false,
        hover: true,
        responsive: true,
        striped: true,
        isLoading: props.applicationState.isLoading,
        data: props.applicationState.clientSummary,
        columns,
        totalSize: props.applicationState.clientSummary.length,
        initialSortingState: []
    }

    return (<>
        <Modal size="lg" show={showEndpointModal} onHide={handleEndpointClose}>
            <Modal.Header closeButton>
                <Modal.Title>Endpoints</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!props.applicationState.isLoadedDetails
                    ?
                    <div className="d-flex justify-content-center mt-3">
                        <Spinner animation="border" variant="primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    :
                    <React.Fragment>
                        <Row>
                            <Col>
                                <h5>{props.applicationState.clientDetails.clientName}</h5>
                            </Col>
                        </Row>
                        {props.applicationState.clientDetails.redirectUris.map((uri: ClientRedirectUri, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    <Row className="mt-4">
                                        <Col sm={2}>
                                            <div>Redirect Uri:</div>
                                        </Col>
                                        <Col sm={8}>
                                            <div>{uri.redirectUri}</div>
                                        </Col>
                                        <Col sm={2}>
                                            <div className='float-end'>
                                                <Clipboard
                                                    className="btn btn-sm btn-primary"
                                                    data-clipboard-text={uri.redirectUri}>
                                                    <FontAwesomeIcon icon={faCopy} className="mr-2" />
                                                    &nbsp;Copy
                                                </Clipboard>
                                                </div>
                                            
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            );
                        })}

                        {props.applicationState.clientDetails.postLogoutRedirectUris.map((uri: ClientPostLogoutRedirectUri, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    <Row className="mt-4">
                                        <Col sm={2}>
                                            <div>Logout Uri:</div>
                                        </Col>
                                        <Col sm={8}>
                                        <div>{uri.postLogoutRedirectUri}</div>
                                        </Col>
                                        <Col sm={2}>
                                        <div className='float-end'>
                                            <Clipboard
                                                className="btn btn-sm btn-primary"
                                                data-clipboard-text={uri.postLogoutRedirectUri}>
                                                <FontAwesomeIcon icon={faCopy} className="mr-2" />
                                                &nbsp;Copy
                                            </Clipboard>
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                </React.Fragment>
                            );
                        })}

                    </React.Fragment>
                }
            </Modal.Body>
        </Modal>
        <Card>
            <Card.Body>
                <SafTable {...tableAttributes} />
            </Card.Body>
        </Card>
    </>)

}