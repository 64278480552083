import axios from 'axios';
import { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Card, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

interface ErrorDto {
    error: string;
    errorDescription?: string;
    clientName?: string;
}


const ErrorPage = () => {
    const query = useQuery();
    const [errorInfo, setErrorInfo] = useState<ErrorDto>();

    useEffect(() => {
        const url = `/api/error?errorId=${query.get("errorId")}`;
        axios.get<ErrorDto>(url)
            .then((response) => {
                setErrorInfo(response.data)
            })
            .catch((error) => {
                console.error("Error getting error info:", error);
            });
    }, []);


    return (
        <Container fluid>
            <Container className="text-center">
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="3x" className="text-danger"/>
                            <h1>Error</h1>
                        </Card.Title>
                        <Card.Text>
                            <p>
                                The last operation resulted in an error ({ errorInfo?.error ? 
                            <span className="fst-italic fw-bolder">{errorInfo.error}</span> : 
                            "unknown"}).
                            </p>
                            {errorInfo?.errorDescription &&
                            <p>
                                {`Description: ${errorInfo?.errorDescription}`}
                            </p>}
                             {errorInfo?.clientName &&
                            <p>
                                {`Client: ${errorInfo?.clientName}`}
                            </p>}
                        </Card.Text>
                        <Link to="/">
                            <Button variant="primary">Back Home</Button>
                        </Link>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    )
};

export default ErrorPage;


