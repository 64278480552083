import { IWorkItemDto } from "../types/IWorkItemDto";

export enum WorkItemAction {
    UpdateWorkItems
}

export interface IWorkItemState {
    isLoading: boolean;
    totalCount: number;
    workItems: IWorkItemDto[];
  }

export const workItemsReducer = (state: IWorkItemState, action: { type: WorkItemAction; value: IWorkItemState }) => {
    switch (action.type) {
        case WorkItemAction.UpdateWorkItems:
            return {
                isLoading: false,
                totalCount: action.value.totalCount,
                workItems: action.value.workItems
            };
        default:
            return state;
    }
};

