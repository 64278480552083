import { combineReducers, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createOidcMiddleware, { USER_FOUND } from 'redux-oidc';
import { UserManager } from 'oidc-client';
import {
    createSignalRMiddleware,
    createUserManager,
} from '@konicaminolta/common-react';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { reducers } from './';
import { appSettings } from '../appSettings';


declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_?: typeof compose;
    }
}

// Create browser history
export const history = createBrowserHistory({
    basename: `/${appSettings.basePath}`,
});

// Create OIDC User Manager
export const userManager: UserManager = createUserManager(appSettings);

const middleware = [
    createOidcMiddleware(userManager),
    routerMiddleware(history),
    createSignalRMiddleware(appSettings),
];

const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
});

const persistConfig = {
    key: 'foundation-service',
    version: 1,
    storage,
    whitelist: ['preferences']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// this is a wrapper for createStore that comes with RTK
// thunk and devTools are included in the default middleware
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // include these ignored actions to use RTK with redux-persist: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                    USER_FOUND,
                ],
                ignoredPaths: ['oidc.user'],
            },
        }).concat(middleware),
});

export const persistor = persistStore(store);