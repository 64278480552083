import navLogo from './assets/km_logo.png';
import squareLogo from './assets/KM-Logo-Square-Color.png';
import { faTachometerAlt, faDesktop, faCode, faUsersCog, faLocationArrow } from "@fortawesome/pro-solid-svg-icons";
import { AppSettings } from "@konicaminolta/common-react";
import ApiEditPage from "./components/Api/ApiEditPage";
import ApiPage from "./components/Api/ApiPage";
import ApplicationEditPage from "./components/Application/ApplicationEditPage";
import ApplicationNoRolesPage from "./components/Application/ApplicationNoRolesPage";
import ApplicationPage from "./components/Application/ApplicationPage";
import DashboardPage from "./components/Dashboard/DashboardPage";
import NavigationPage from "./components/Navigation/NavigationPage";
import ProfilePage from "./components/Profile/ProfilePage";
import RoleEditPage from "./components/Role/RoleEditPage";
import RolePage from "./components/Role/RolePage";
import ErrorPage from "./components/Auth/ErrorPage";
import { ROLE_FULL_ADMINISTRATOR, SERVICE_ROLES, ROLE_READ_ONLY_ADMINISTRATOR } from './appRoles';

// Configure service Settings
export const appSettings: AppSettings = {
    navLogoAsset: navLogo,
    squareLogoAsset: squareLogo,
    basePath: "", // No slashes - empty string if no base path
    adminRole: ROLE_FULL_ADMINISTRATOR,
    appRoles:  SERVICE_ROLES,
    requireValidRole: false,
    clientId: "3a0d437a-77b1-4dd5-8460-157d8518019b",
    apiScopes: [ 
        "586fac2a-cb90-4fc0-872e-501794c1ece4", // FS
        "9c7ddfd2-18de-490e-bbab-8ecd41e78759", // Notification Service
    ],
    routes: [
        {
            name: "Error",
            path: "/error",
            component: ErrorPage
        },
        {
            name: "Profile",
            path: "/profile",
            component: ProfilePage
        },
        {
            name: "No Application roles",
            path: "/applications/:clientId/no-roles",
            component: ApplicationNoRolesPage
        },
        {
            name: "Application Details",
            path: "/applications/:id",
            component: ApplicationEditPage,
            requireAnyRoles: [ ROLE_FULL_ADMINISTRATOR, ROLE_READ_ONLY_ADMINISTRATOR ]
        },
        {
            name: "Applications",
            path: "/applications",
            requireAnyRoles: [ ROLE_FULL_ADMINISTRATOR, ROLE_READ_ONLY_ADMINISTRATOR ],
            component: ApplicationPage,
        },
        {
            name: "API Details",
            path: "/api/:id",
            component: ApiEditPage,
            requireAnyRoles: [ ROLE_FULL_ADMINISTRATOR, ROLE_READ_ONLY_ADMINISTRATOR ]
        },
        {
            name: "APIs",
            path: "/api",
            requireAnyRoles: [ ROLE_FULL_ADMINISTRATOR, ROLE_READ_ONLY_ADMINISTRATOR ],
            component: ApiPage
        },
        {
            name: "Role Details",
            path: "/roles/:id",
            component: RoleEditPage,
            requireAnyRoles: [ ROLE_FULL_ADMINISTRATOR, ROLE_READ_ONLY_ADMINISTRATOR ]
        },
        {
            name: "Roles",
            path: "/roles",
            component: RolePage,
            requireAnyRoles: [ ROLE_FULL_ADMINISTRATOR, ROLE_READ_ONLY_ADMINISTRATOR  ],
        },
        {
            name: "Navigation",
            path: "/navigation",
            component: NavigationPage,
            requireAnyRoles: [ ROLE_FULL_ADMINISTRATOR, ROLE_READ_ONLY_ADMINISTRATOR  ],
        },
        {
            name: "Dashboard",
            path: "/",
            component: DashboardPage
        }
    ],
    menuItems: [
        {
            name: "Dashboard",
            path: "/",
            menuIcon: faTachometerAlt    
        },
        {
            name: "Applications",
            path: "/applications",
            menuIcon: faDesktop
        },
        {
            name: "APIs",
            path: "/api",
            menuIcon: faCode
        },
        {
            name: "Roles",
            path: "/roles",
            menuIcon: faUsersCog
        },
        {
            name: "Navigation",
            path: "/navigation",
            menuIcon: faLocationArrow
        }
    ],
    signalR: {
        actionHandlers: [],
        eventHandlers: []
    }
}