import { Fragment, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import axios from 'axios';
import FormattedEventDateTime from "../../Shared/FormattedEventDateTime";
import { IWorkItemDto } from "./types/IWorkItemDto";
import { IWorkItemSearchResponseDto } from "./types/IWorkItemSearchResponseDto";
import _ from "lodash";
import { ApiRequestAction, apiRequestReducer } from "./reducers/apiRequestReducer";
import { WorkItemAction, IWorkItemState, workItemsReducer } from "./reducers/workItemsReducer";
import ConcatenatedString from "./formatters/ConcatenatedString";
import WorkItemDetail from "./WorkItemDetail";
import { faTools, faBug } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "react-bootstrap";
import { ISafTableColumnDef, ISafTableFilter, ISafTableFilterDefinition, ISafTablePaginationState, ISafTableProps, ISafTableSearchRequest, ISafTableSortingState, SafTable, useReadLocalStorage } from "@konicaminolta/common-react";
import { SortDirection } from "../../Shared/SortDirection";



const WorkItemsTable = () => {
  const tableKey = "FS:WorkItemsTable"
  const pageSize = useReadLocalStorage(`${tableKey}-page-size`);
  const intialSearchFilters = useReadLocalStorage<ISafTableFilter[]>(`${tableKey}-filters`);
  const initialWorkItemState: IWorkItemState = {
    isLoading: true,
    totalCount: 0,
    workItems: []
  }

  const initialApiRequest: ISafTableSearchRequest = {
    take: pageSize ? Number(pageSize) : 10,
    skip: 0,
    searchTerm: '',
    filters: intialSearchFilters ?? [],
    sort: { Property: "submittedDateUtc", Direction: SortDirection.asc }
  }


  const [apiRequest, dispatchApiRequestChange] = useReducer(apiRequestReducer, initialApiRequest);


  const [workItemState, dispatchWorkItemChange] = useReducer(workItemsReducer, initialWorkItemState);

  const [showDetail, setShowDetail] = useState<boolean>(false);
  const handleClose = () => setShowDetail(false);
  const [selectedWorkItem, setSelectedWorkItem] = useState<IWorkItemDto>();



  const addWorkItems = useCallback(() => {
    const searchUrl = `/api/workitems/search`;
    axios.post<IWorkItemSearchResponseDto>(searchUrl, apiRequest)
      .then((response) => {
        dispatchWorkItemChange({
          type: WorkItemAction.UpdateWorkItems,
          value: {
            isLoading: false,
            totalCount: response.data.count,
            workItems: response.data.data
          }
        })
      })
      .catch((error) => {
        console.error("Error getting work items:", error);
      });
  }, [apiRequest]);



  useEffect(() => { addWorkItems() }, [addWorkItems]);

  const handleRowClick = (data: IWorkItemDto) => {
    console.log("data", data);
    setSelectedWorkItem(data);
    setShowDetail(true);
  }

  const handleSearch = (searchTerm: string) => dispatchApiRequestChange({
    type: ApiRequestAction.UpdateSearchTerm,
    value: { ...apiRequest, searchTerm }
  })

  const handleFilters = (filters: ISafTableFilter[]) => dispatchApiRequestChange({
    type: ApiRequestAction.UpdateFilters,
    value: { ...apiRequest, filters }
  })

  const handleSort = (sorting: ISafTableSortingState) => dispatchApiRequestChange({
    type: ApiRequestAction.UpdateSorting,
    value: {
      ...apiRequest,
      sort: {
        Property: sorting[0].id,
        Direction: sorting[0].desc
          ? SortDirection.desc
          : SortDirection.asc
      }
    }
  })

  const handlePagination = (pagination: ISafTablePaginationState) => dispatchApiRequestChange({
    type: ApiRequestAction.UpdatePagination,
    value: {
      ...apiRequest,
      skip: pagination.pageIndex * pagination.pageSize,
      take: pagination.pageSize,
    }
  });


  const columns: ISafTableColumnDef<IWorkItemDto>[] = useMemo(() => {
    return [
      {
        accessorKey: "projectWorkItemId",
        id: 'projectWorkItemId',
        manualSorting: true,
        accessorFn: (row: IWorkItemDto) => row.projectWorkItemId ?? "Pending",
        header: () => <span className="sortable-header">Work Id</span>
      },
      {
        accessorKey: "type",
        id: 'type',
        manualSorting: true,
        header: () => <span className="sortable-header">Type</span>,
        cell: (row) => {
          var workItemType = row.getValue() as string;
          if (workItemType === "Bug") {
            return (
              <Stack direction="horizontal" gap={1}>
                <div><FontAwesomeIcon icon={faBug} className="text-danger" size="sm"/></div>
                <div>{row.getValue() as string}</div>
              </Stack>
            )
          }
          return (
            <Stack direction="horizontal" gap={1}>
              <div><FontAwesomeIcon icon={faTools} className="text-primary" size="sm"/></div>
              <div>{row.getValue() as string}</div>
            </Stack>
          )
        }
      },
      {
        accessorKey: "subjectName",
        id: 'subjectName',
        manualSorting: true,
        header: () => <span className="sortable-header">Subject</span>
      },
      {
        accessorKey: "title",
        id: 'title',
        manualSorting: true,
        header: () => <span className="sortable-header">Title</span>
      },
      {
        accessorKey: "description",
        id: 'description',
        manualSorting: true,
        header: () => <span className="sortable-header">Description</span>,
        cell: (row) => <span className="text-wrap"><ConcatenatedString description={row.getValue() as string} range={100} /></span>
      },
      {
        accessorKey: "state",
        id: 'state',
        manualSorting: true,
        accessorFn: (row: IWorkItemDto) => row.state ?? "Submitted",
        header: () => <span className="sortable-header">State</span>
      },
      {
        accessorKey: "submittedDateUtc",
        id: 'submittedDateUtc',
        manualSorting: true,
        header: () => <span className="sortable-header">Sumitted Date</span>,
        cell: (row) => <FormattedEventDateTime date={row.getValue() as string} />
      },
    ];
  }, []);

  const filterDefs: ISafTableFilterDefinition[] = [
    {
      property: "type",
      label: "Type",
      values: ["Feature", "Bug"]
    }
  ]


  const tableAttributes: ISafTableProps<IWorkItemDto> = {
    tableKey,
    bordered: false,
    hover: true,
    responsive: true,
    striped: true,
    handleSearch,
    handleFilters,
    handlePagination,
    handleSort,
    handleRowClick,
    isLoading: workItemState.isLoading,
    data: workItemState.workItems,
    columns,
    filters: filterDefs,
    totalSize: workItemState.totalCount,
    initialSortingState: []
  }

  return (
    <Fragment>
      <SafTable {...tableAttributes} />
      <WorkItemDetail show={showDetail} handleClose={handleClose} workItem={selectedWorkItem} />
    </Fragment>
  )
};

export default WorkItemsTable;