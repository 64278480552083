import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { SessionActionTypes, RECEIVE_SESSION, REQUEST_SESSION, SUBMIT_UPDATE_TIME_ZONE, RECEIVE_UPDATE_TIME_ZONE_RESPONSE } from '../types/actions';
import { SessionInfo } from '../types/Session/SessionInfo';
import { SessionTimeZone } from '../types/Session/SessionTimeZone';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SessionState {
    isLoading: boolean;
    isLoaded: boolean;
    isUpdatingTimeZone: boolean;
    session: SessionInfo;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestSession: (): AppThunkAction<SessionActionTypes> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState 
            && appState.session !== undefined 
            && !appState.session.isLoading 
            && !appState.session.isLoaded) 
        {

            dispatch({ type: REQUEST_SESSION });

            axios
                .request<SessionInfo>({
                    url: './api/session/info'
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_SESSION, session: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },

    updateTimeZone: (timeZone: SessionTimeZone): AppThunkAction<SessionActionTypes> => (dispatch, getState) => {

        const appState = getState();

        if (appState 
            && appState.session !== undefined 
            && !appState.session.isUpdatingTimeZone)
        {
            dispatch({ type: SUBMIT_UPDATE_TIME_ZONE });
            axios
                .patch(
                    `./api/session/timezone`,
                    timeZone    
                )
                .then((response) => {
                    dispatch({ type: RECEIVE_UPDATE_TIME_ZONE_RESPONSE });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
};

export const reducer: Reducer<SessionState> = (state: SessionState | undefined, incomingAction: Action): SessionState => {
    if (state === undefined) {
        return {
            isLoading: false, 
            isLoaded: false,
            isUpdatingTimeZone: false,
            session: {
                principalId: "",
                employeeId: "",
                firstName: "",
                preferredFirstName: null,
                lastName: "",
                fullName: "",
                emailAddress: null,
                jobTitle: "",
                jobCode: "",
                timeZone: "",
                roles: []
            }
        }
    }

    const action = incomingAction as SessionActionTypes;
    switch (action.type) {
        case REQUEST_SESSION:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        case RECEIVE_SESSION:
            return {
                ...state,
                session: action.session,
                isLoading: false,
                isLoaded: true,
            };
        case SUBMIT_UPDATE_TIME_ZONE:
            return {
                ...state,
                isUpdatingTimeZone: true,
            };
        case RECEIVE_UPDATE_TIME_ZONE_RESPONSE:
            return {
                ...state,
                isUpdatingTimeZone: false,
            };
    }

    return state;
};
