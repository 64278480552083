import * as React from 'react';
import * as RoleStore from '../../store/Role';
import { RoleMember } from '../../types/Role/RoleMember';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { User } from 'oidc-client';
import { ROLE_FULL_ADMINISTRATOR } from '../../appRoles';
import { RoleMemberType } from '../../types/Role/RoleMemberType';
import RoleMemberTypeDisplay from './RoleMemberTypeDisplay';

type RoleMemberTableProps = {
    user: User;
    roleId: string;
    roleState: RoleStore.RoleState;
    actions: typeof RoleStore.actionCreators;
}

interface RoleMemberTableState {
    showModal: boolean;
    deleteRoleMember: RoleMember;
}

class RoleMemberTable extends React.Component<RoleMemberTableProps, RoleMemberTableState> {
    public constructor(props: RoleMemberTableProps) {
        super(props);
        this.state = {
            showModal: false,
            deleteRoleMember: {
                id: '',
                type: undefined,
                displayName: ''
            }
        }
    }

    _pageSize = 50;
    _timeZone: string = "Universal";

    public componentDidMount() {
        this.props.actions.unloadRoleMemberSummary();
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.actions.requestRoleMemberSummary(this.props.roleId);
    }

    private handleOpen(){
        this.setState({
            showModal: true
        });
    }
    
    private handleClose(){
        this.setState({
            showModal: false
        });
    }
    
    private deleteRole(roleMember: RoleMember){
        this.setState({deleteRoleMember: roleMember});
        this.handleOpen();
    }

    private processDeleteRole(){
        this.props.actions.submitDeleteMember(this.props.roleId, this.state.deleteRoleMember);
        this.handleClose();
    }

    render() {

        const userRoles: string[] = this.props.user?.profile?.role ? this.props.user.profile.role : [];

        const NoDataComponent: React.FunctionComponent = (): React.ReactElement => {
            if(this.props.roleState.isLoaded){
                return (
                    <div className="d-flex justify-content-center mt-3" data-cy="bootstrap-table-loaded">
                        <p className="text-center">No roles were found.</p>
                    </div>
                );
            }
            else{
                return (
                    <div className="d-flex justify-content-center mt-3">
                        <Spinner animation="border" variant="primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                );
            }
        };

        const { SearchBar } = Search;

        const columns = [
            {
                dataField: 'type',
                text: 'Type',
                sort: true,
                headerStyle: () => {
                    return { width: '250px'};
                },
                formatter: (type: string) => {
                    return (
                        <RoleMemberTypeDisplay type={RoleMemberType[type as keyof typeof RoleMemberType]}/>
                    )
                }
            },
            {
                dataField: 'displayName',
                text: 'Display Name',
                sort: true
            },
            {
                dataField: 'id',
                text: '',
                sort: false,
                align: 'right',
                hidden: !userRoles.includes(ROLE_FULL_ADMINISTRATOR),
                headerStyle: () => {
                    return { width: '80px'};
                },
                formatter: (id: number, row: RoleMember) => {
                    return (
                        <React.Fragment>
                            <FontAwesomeIcon className="clickable text-dark" icon={faTrash} onClick={() => this.deleteRole(row)} />
                        </React.Fragment>
                    )
                }
            }
        ];

        return (
            <React.Fragment>
                <ToolkitProvider
                    keyField="id"
                    search
                    data={ this.props.roleState.roleMemberSummary } 
                    columns={ columns } 
                    >
                    {
                        props => (
                        <div>
                            <SearchBar { ...props.searchProps } />
                            <BootstrapTable
                                { ...props.baseProps }
                                striped
                                bordered={false}
                                defaultSorted={ [{ dataField: 'name', order: 'asc' }] }
                                pagination={ paginationFactory({sizePerPage: 25}) }
                                noDataIndication={ () => <NoDataComponent /> }
                            />
                        </div>
                        )
                    }
                </ToolkitProvider>
                <Modal size="lg" show={this.state.showModal} onHide={() => this.handleClose()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Role</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col className="text-center">
                                    <h5>Whoa! You almost deleted a Role Member. Are you sure about this?</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center mt-3">
                                    <strong>Role Member: </strong> {this.state.deleteRoleMember.displayName}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center mt-5">
                                    <Button variant="danger" onClick={() => this.processDeleteRole()}>
                                        Delete Role Member Forever
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

// function mapStateToProps(state: AppState) {
//     return {
//         user: state.oidc.user
//     };
// }

// export default connect(
//     mapStateToProps
// )(RoleMemberTable as any)
export default RoleMemberTable;