import React from 'react';
import { formatDistance, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';

const FormattedEventDateTime = ({ date }: { date: string }): JSX.Element => {
    const parsedDateTime = parseISO(date);
    const calendarDateTime = format(parsedDateTime, 'MMMM dd, yyyy hh:ssa');
    const textDateDistance = formatDistance(parsedDateTime, Date.now());

    return (
        <div>
            <span>{calendarDateTime}</span>
            <br />
            <small className="text-muted">{`${textDateDistance} ago`}</small>
        </div>
    );
};

export default FormattedEventDateTime;
