import axios from 'axios';
import * as React from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { DashboardEntryDto } from '../../types/Dashboard/DashboardEntryDto';

type ApplicationNoRolesPageProps = {
}
    & RouteComponentProps<{ clientId: string }>;

interface ApplicationNoRolesPageState {
    isLoading: boolean;
    isLoaded: boolean;
    dashboardEntry: DashboardEntryDto | undefined;
}

class ApplicationNoRolesPage extends React.Component<ApplicationNoRolesPageProps, ApplicationNoRolesPageState> {
    constructor(props: ApplicationNoRolesPageProps) {
        super(props);
        this.state = {
            isLoading: false,
            isLoaded: false,
            dashboardEntry: undefined
        };
    }

    public componentDidMount() {
        this.getErrorMessage();
    }

    private getErrorMessage(){

        this.setState({ isLoading: true });

        axios
            .request<DashboardEntryDto>({
                url: `./api/dashboard/${this.props.match.params.clientId}`
            })
            .then((response) => {
                const { data } = response;
                this.setState({ 
                    isLoading: false,
                    isLoaded: true,
                    dashboardEntry: data,
                });
            })
            .catch((error) => {
                this.setState({ 
                    isLoading: false,
                    isLoaded: true,
                    dashboardEntry: undefined
                });
                console.log(error);
            });
    }

    public render() {
        if(this.state.isLoading) {
            return (
                <React.Fragment>
                    <Row className="dashboardEntries" >
                        <Col md={12}>
                            <Card className="dashboardEntry">
                                <Card.Body>
                                    <Card.Title>Loading...</Card.Title>
                                    <Card.Text>
                                        <Spinner animation="border" variant="primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }

        if(this.state.isLoaded 
            && !this.state.isLoading 
            && this.state.dashboardEntry === undefined){
            return (
                <React.Fragment>
                    <Row className="dashboardEntries" >
                        <Col md={12}>
                            <Card className="dashboardEntry">
                                <Card.Body>
                                    <Card.Title>No Application Found</Card.Title>
                                    <Card.Text>
                                        <p>It looks as though you may have tried to access an application which either does not exist or is not properly configured.</p>
                                    </Card.Text>
                                    <Card.Text>
                                        <p>If you believe this is in error, please contact the <a href="mailto:ats-automation@allcovered.com">ATS Automation</a> team.</p>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Link to="/">
                                        <Button variant="primary">Back to Dashboard</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Row className="dashboardEntries" >
                    <Col md={12}>
                        <Card className="dashboardEntry">
                            <Card.Body>
                                <Card.Title>Insufficient Access Rights</Card.Title>
                                <Card.Text>
                                    <p>It looks as though you may have tried to access an application for which you have insufficient access rights.</p>
                                </Card.Text>
                                {(this.state.dashboardEntry !== undefined) &&
                                    <Card.Text>
                                        <p>The application you tried to access is:</p>
                                        <div className="fake-disabled-textarea">
                                            <strong>{this.state.dashboardEntry.name}</strong>
                                            <hr/>
                                            <ReactMarkdown 
                                                children={this.state.dashboardEntry.description}
                                                linkTarget="_blank"
                                            />
                                        </div>
                                    </Card.Text>   
                                }
                                <Card.Text>
                                    <p>If you believe you should have access to this application, please contact the <a href="mailto:ats-automation@allcovered.com">ATS Automation</a> team.</p>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Link to="/">
                                    <Button variant="primary">Back to Dashboard</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default ApplicationNoRolesPage;