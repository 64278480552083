import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as ApplicationStore from '../../store/Application';
import * as ApiStore from '../../store/Api';
import { Client } from '../../types/Application/Client';
import { GrantTypes, GrantType } from '../../types/Application/GrantTypes';
import { ClientRedirectUri } from '../../types/Application/ClientRedirectUri';
import { ClientPostLogoutRedirectUri } from '../../types/Application/ClientPostLogoutRedirectUri';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/pro-solid-svg-icons' 
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { ApiResource } from '../../types/Api/ApiResource';
import { ClientSecret } from '../../types/Application/ClientSecret';
import { ClientCorsOrigin } from '../../types/Application/ClientCorsOrigin';
import ReactMarkdown from 'react-markdown';

type ApplicationEditPageProps = {
    applicationState: ApplicationStore.ApplicationState;
    apiState: ApiStore.ApiState;
    actions: typeof ApplicationStore.actionCreators 
        & typeof ApiStore.actionCreators;
}
    & RouteComponentProps<{ id: string }>;

interface ApplicationEditPageState {
    isLoaded: boolean;
    client: Client;
    clientSecret: ClientSecret;
}

class ApplicationEdit extends React.Component<ApplicationEditPageProps, ApplicationEditPageState> {

    constructor(props: ApplicationEditPageProps) {
        super(props);
        this.state = {
            isLoaded: false,
            client: {
                clientId: '',
                clientName: '',
                clientUri: '',
                logoUri: '',
                navigationPath: '',
                description: '',
                enabled: false,
                allowOfflineAccess: false,
                requireClientSecret: false,
                allowAccessTokensViaBrowser: false,
                requireConsent: false,
                created: '',
                redirectUris: [],
                allowedGrantTypes: [],
                postLogoutRedirectUris: [],
                allowedCorsOrigins: [],
                allowedScopes: [],
                claims: [],
            },
            clientSecret: {
                secret: '',
            },
        };
    }

    _grantTypes : GrantType[] = [
        {value: GrantTypes.Implicit, label: 'Implicit'},
        {value: GrantTypes.Code, label: 'Code'},
        {value: GrantTypes.Hybrid, label: 'Hybrid'},
        {value: GrantTypes.ClientCredentials, label: 'Client Credentials'},
        {value: GrantTypes.ResourceOwnerPassword, label: 'Resource Owner Password'},
        {value: GrantTypes.DeviceFlow, label: 'Device Flow'},
        {value: GrantTypes.Delegation, label: 'Delegation'}
    ];
    
    public componentDidMount() {
        this.props.actions.unloadApplicationDetails();
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
        
        if(!this.state.isLoaded && this.props.applicationState.isLoadedDetails){
            this.setState({
                isLoaded: true,
                client: {
                    ...this.props.applicationState.clientDetails
                }
            });
        }
    }

    private ensureDataFetched() {
        this.props.actions.requestApplicationDetails(this.props.match.params.id);
        this.props.actions.requestApiSummary();
    }
   
    public render() {
        return (
            <React.Fragment>
                {!this.props.applicationState.clientDetails
                ?
                <Alert variant="danger" className="text-center">
                    <strong>An error occurred while trying to retrieve the requested Application. Please ensure you are using a valid Application id.</strong>
                </Alert>
                :
                <Row>
                    <Col>
                        <Card className="panel">
                            <Card.Header>
                                <Row>
                                <Col md={6}>
                                    <h3>Edit Application</h3>
                                </Col>
                                <Col md={6} className="text-right">
                                    <Button variant="secondary" as={Link as any} to={`/applications`}>
                                        <FontAwesomeIcon className="back-btn" icon={faReply} />
                                    </Button>
                                </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                {this.props.applicationState.isLoadedDetails && this.state.isLoaded
                                ?
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <Card className="panel">
                                                <Card.Body>
                                                    <Form.Group as={Row}>
                                                        <Form.Label column sm="3">Client ID</Form.Label>
                                                        <Col sm="9">
                                                            <Form.Control 
                                                                type="text"
                                                                required 
                                                                readOnly
                                                                minLength={1}
                                                                value={this.state.client.clientId}
                                                            />
                                                            <small className="text-muted">A unique id for the application</small>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-4">
                                                        <Form.Label column sm="3">Client Name</Form.Label>
                                                        <Col sm="9">
                                                            <Form.Control 
                                                                type="text"
                                                                required 
                                                                readOnly
                                                                minLength={1}
                                                                value={this.state.client.clientName}
                                                            />
                                                            <small className="text-muted">Client display name (used for logging and consent screen)</small>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-4">
                                                        <Form.Label column sm="3">Client Description</Form.Label>
                                                        <Col sm="9">
                                                            <div className="fake-disabled-textarea">
                                                                <ReactMarkdown 
                                                                    children={this.state.client.description}
                                                                    linkTarget="_blank"
                                                                />
                                                            </div>
                                                            <small className="text-muted">Client description (markdown content used for FS dashboard)</small>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-2">
                                                        <Form.Label column sm="3">Client Uri</Form.Label>
                                                        <Col sm="9">
                                                            <Form.Control 
                                                                type="text"
                                                                required 
                                                                readOnly
                                                                minLength={1}
                                                                value={this.state.client.clientUri}
                                                            />
                                                            <small className="text-muted">URI to further information about client (used on consent screen)</small>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-2">
                                                        <Form.Label column sm="3">Logo Uri</Form.Label>
                                                        <Col sm="9">
                                                            <Form.Control 
                                                                type="text"
                                                                required 
                                                                readOnly
                                                                minLength={1}
                                                                value={this.state.client.logoUri}
                                                                placeholder="None Set"
                                                            />
                                                            <small className="text-muted">URI for the logo which appears on the dashboard.</small>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-2">
                                                        <Form.Label column sm="3">Navigation Path</Form.Label>
                                                        <Col sm="9">
                                                            <Form.Control 
                                                                type="text"
                                                                required 
                                                                readOnly
                                                                minLength={1}
                                                                value={this.state.client.navigationPath}
                                                                placeholder="None Set"
                                                            />
                                                            <small className="text-muted">Navigation path for the client.</small>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-0">
                                                        <Form.Label column sm="4">Enabled</Form.Label>
                                                        <Col sm="8">
                                                            <Form.Check 
                                                                type="switch"
                                                                disabled
                                                                label=""
                                                                id="client-enabled"
                                                                checked={this.state.client.enabled}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-0">
                                                        <Form.Label column sm="4">Offline Access</Form.Label>
                                                        <Col sm="8">
                                                            <Form.Check 
                                                                type="switch"
                                                                disabled
                                                                label=""
                                                                id="allow-offline-access"
                                                                checked={this.state.client.allowOfflineAccess}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-0">
                                                        <Form.Label column sm="4">Require Client Secret</Form.Label>
                                                        <Col sm="8">
                                                            <Form.Check 
                                                                type="switch"
                                                                label=""
                                                                disabled
                                                                id="require-client-secret"
                                                                checked={this.state.client.requireClientSecret}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-0">
                                                        <Form.Label column sm="4">Browser Access Tokens</Form.Label>
                                                        <Col sm="8">
                                                            <Form.Check 
                                                                type="switch"
                                                                disabled
                                                                label=""
                                                                id="allow-access-tokens-via-browser"
                                                                checked={this.state.client.allowAccessTokensViaBrowser}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mt-0">
                                                        <Form.Label column sm="4">Require Consent</Form.Label>
                                                        <Col sm="8">
                                                            <Form.Check 
                                                                type="switch"
                                                                disabled
                                                                label=""
                                                                id="require-consent"
                                                                checked={this.state.client.requireConsent}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <h4>Grant Types</h4>
                                                    <hr/>
                                                    <Row>
                                                        <Col md={6}>
                                                            {this._grantTypes.map((grant: GrantType, index: number) => {
                                                                
                                                                let checked = false;

                                                                if(this.state.client.allowedGrantTypes.find(g =>  g.grantType === grant.value)){
                                                                    checked = true;
                                                                }

                                                                if(index <= ~~(this._grantTypes.length / 2)){
                                                                    return (
                                                                        <Form.Check 
                                                                            disabled
                                                                            key={index} 
                                                                            defaultChecked={checked} 
                                                                            className="mt-2" 
                                                                            label={grant.label}
                                                                            value={grant.value}
                                                                            type="checkbox" 
                                                                            id={`grant-${index}`}
                                                                        />
                                                                    );
                                                                }

                                                                return (<React.Fragment></React.Fragment>);
                                                            })}
                                                        </Col>
                                                        <Col md={6}>
                                                            {this._grantTypes.map((grant: GrantType, index: number) => {
                                                                if(index > ~~(this._grantTypes.length / 2)){
                                                                    
                                                                    let checked = false;

                                                                    if(this.state.client.allowedGrantTypes.find(g =>  g.grantType === grant.value)){
                                                                        checked = true;
                                                                    }

                                                                    return (
                                                                        <Form.Check 
                                                                            disabled
                                                                            key={index} 
                                                                            defaultChecked={checked} 
                                                                            className="mt-2" 
                                                                            label={grant.label}
                                                                            value={grant.value}
                                                                            type="checkbox" 
                                                                            id={`grant-${index}`}
                                                                        />
                                                                    );
                                                                }

                                                               return (<React.Fragment></React.Fragment>);
                                                            })}
                                                        </Col>
                                                    </Row>                                                    
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={6}>
                                            <Card className="panel">
                                                <Card.Body>
                                                    <h4>Endpoints</h4>
                                                    <hr/>
                                                    {this.state.client.postLogoutRedirectUris.map((uri: ClientPostLogoutRedirectUri, index: number) => {
                                                        return (
                                                            <Form.Group key={index} as={Row} className="mt-2">
                                                                <Form.Label column sm="3">Post Logout</Form.Label>
                                                                <Col sm="8">
                                                                    <Form.Control 
                                                                        type="text"
                                                                        readOnly
                                                                        required 
                                                                        minLength={1}
                                                                        value={uri.postLogoutRedirectUri}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        );
                                                    })}
                                                    {this.state.client.redirectUris.map((uri: ClientRedirectUri, index: number) => {
                                                        return (
                                                            <Form.Group key={index} as={Row} className="mt-2">
                                                                <Form.Label column sm="3">Redirect</Form.Label>
                                                                <Col sm="9">
                                                                    <Form.Control 
                                                                        type="text"
                                                                        readOnly
                                                                        required 
                                                                        minLength={1}
                                                                        value={uri.redirectUri}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        );
                                                    })}
                                                    <h4>Cors Origins</h4>
                                                    <hr/>
                                                    {this.state.client.allowedCorsOrigins.map((cors: ClientCorsOrigin, index: number) => {
                                                        return (
                                                            <Form.Group key={index} as={Row} className="mt-2">
                                                                <Form.Label column sm="3">Origin</Form.Label>
                                                                <Col sm="9">
                                                                    <Form.Control 
                                                                        type="text"
                                                                        readOnly
                                                                        required 
                                                                        minLength={1}
                                                                        value={cors.origin}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        );
                                                    })}
                                                    <h4>Allowed Scopes</h4>
                                                    <hr/>
                                                    <Form.Group as={Row}>
                                                        <Form.Label column sm="3">Scopes</Form.Label>
                                                        <Col sm="9">
                                                            <Form.Control 
                                                                as="textarea" 
                                                                readOnly
                                                                rows={1} 
                                                                disabled
                                                                value={this.state.client.allowedScopes.map(scope => {
                                                                    return ` ${scope.scope}`;
                                                                })}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <h4>API's</h4>
                                                    <hr/>
                                                    <Row>
                                                        <Col md={6}>
                                                            {this.props.apiState.apiResourceSummary.map((api: ApiResource, index: number) => {
                                                                
                                                                let checked = false;

                                                                if(this.state.client.allowedScopes.find(s =>  s.scope === api.id)){
                                                                    checked = true;
                                                                }

                                                                if(index <= ~~(this.props.apiState.apiResourceSummary.length / 2)){
                                                                    return (
                                                                        <Form.Check 
                                                                            disabled
                                                                            key={index} 
                                                                            defaultChecked={checked} 
                                                                            className="mt-2" 
                                                                            label={api.displayName}
                                                                            value={api.id}
                                                                            type="checkbox" 
                                                                            id={`api-${index}`}
                                                                        />
                                                                    );
                                                                }

                                                                return (<React.Fragment></React.Fragment>);
                                                            })}
                                                        </Col>
                                                        <Col md={6}>
                                                            {this.props.apiState.apiResourceSummary.map((api: ApiResource, index: number) => {
                                                                
                                                                let checked = false;

                                                                if(this.state.client.allowedScopes.find(s =>  s.scope === api.id)){
                                                                    checked = true;
                                                                }

                                                                if(index > ~~(this.props.apiState.apiResourceSummary.length / 2)){
                                                                    return (
                                                                        <Form.Check 
                                                                            disabled
                                                                            key={index} 
                                                                            defaultChecked={checked} 
                                                                            className="mt-2" 
                                                                            label={api.displayName}
                                                                            value={api.id}
                                                                            type="checkbox" 
                                                                            id={`api-${index}`}
                                                                        />
                                                                    );
                                                                }

                                                                return (<React.Fragment></React.Fragment>);
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                                :
                                <div className="d-flex justify-content-center mt-3">
                                    <Spinner animation="border" variant="primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                }
            </React.Fragment>
        );
    }

}

function mapStateToProps(state: AppState) {
    return {
        applicationState: state.application,
        apiState: state.api,
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign(
                {}, 
                ApplicationStore.actionCreators, 
                ApiStore.actionCreators,
            ), dispatch )
        }
    }
)(ApplicationEdit as any);
