import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as DashboardStore from '../../store/Dashboard';
import Row from 'react-bootstrap/Row';
import DashboardEntryComponent from './DashboardEntryComponent';
import { Card, Col, Spinner } from 'react-bootstrap';
import { User } from 'oidc-client';
import DashboardHeaderComponent from './DashboardHeaderComponent';
import {DashboardEntryDto} from "../../types/Dashboard/DashboardEntryDto";

type DashboardPageProps = {
    user: User;
    dashboardState: DashboardStore.DashboardState;
    actions: typeof DashboardStore.actionCreators;
}

class DashboardPage extends React.Component<DashboardPageProps> {

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.actions.requestDashboard();
    }

    public render() {
        if(this.props.dashboardState.isLoading) {
            return (
                <React.Fragment>
                    <DashboardHeaderComponent user={this.props.user}/>
                    <Row className="dashboardEntries">
                        <Col md={12}>
                            <Card className="dashboardEntry">
                                <Card.Body>
                                    <Card.Title>Loading...</Card.Title>
                                    <Card.Text>
                                        <Spinner animation="border" variant="primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }

        if(this.props.dashboardState.isLoaded 
            && !this.props.dashboardState.isLoading 
            && this.props.dashboardState.dashboardEntries?.length === 0){
            return (
                <React.Fragment>
                    <DashboardHeaderComponent user={this.props.user}/>
                    <Row className="dashboardEntries">
                        <Col md={12}>
                            <Card className="dashboardEntry">
                                <Card.Body>
                                    <Card.Title>No User Dashboards Found</Card.Title>
                                    <Card.Text>
                                        <p>No user dashboards were found. You may not have access to any applications in this framework.</p>
                                        <p>If you feel this is in error, please contact the <a href="mailto:ats-automation@allcovered.com">ATS Automation</a> team.</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <DashboardHeaderComponent user={this.props.user}/>
                <Row className="d-flex align-content-stretch flex-wrap dashboardEntries">
                    {this.props.dashboardState.dashboardEntries?.map((dashboardEntry : DashboardEntryDto, index: number) => 
                        <DashboardEntryComponent key={index} dashboardEntry={dashboardEntry} />
                    )}
                </Row>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: AppState) {
    return {
        user: state.oidc.user,
        dashboardState: state.dashboard
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, DashboardStore.actionCreators), dispatch )
        }
    }
)(DashboardPage as any);
