import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router';
import { unregister } from './registerServiceWorker';
import { OidcProvider } from "redux-oidc";
import './scss/app.scss'
import { ScrollToTop, SecuredApplication, configureAxios } from '@konicaminolta/common-react';
import { Route, Switch } from 'react-router';
import LoginPage from './components/Auth/LoginPage';
import LogoutPage from './components/Auth/LogoutPage';
import { appSettings } from './appSettings';
import { configureStore } from '@reduxjs/toolkit';
import { history, persistor, store, userManager } from './store/configureStore';

// Unregistering the service worker to prevent cache issues when new versions are deployed 
unregister();

// Exporting to use to create typed hooks
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof configureStore>;

// Configure Axios
configureAxios(store, appSettings);

if (window.location.pathname === `/silent-renew`) {
    console.log("[App] silent-renew");
    userManager.signinSilentCallback();
} else {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <OidcProvider store={store} userManager={userManager}>
                    <ConnectedRouter history={history}>
                        <ScrollToTop/>
                        <Switch>
                            <Route exact path="/login" component={LoginPage} /> 
                            <Route exact path="/logout" component={LogoutPage} /> 
                            <Route>
                                <SecuredApplication appSettings={appSettings} userManager={userManager} history={history} />
                            </Route>
                        </Switch>
                    </ConnectedRouter>
                </OidcProvider>
            </PersistGate>
        </Provider>,
        document.getElementById('root'));
}

