import * as React from 'react';
import { Badge } from 'react-bootstrap';
import { RoleMemberType } from '../../types/Role/RoleMemberType';

type RoleMemberTypeDisplayProps = {
    type: RoleMemberType;
}

class RoleMemberTypeDisplay extends React.Component<RoleMemberTypeDisplayProps> {
    public render() {
        switch(this.props.type) {
            case RoleMemberType.FoundationApiResource:
                return ( 
                    <span><Badge bg="primary">FS</Badge> API Resource</span>
                )

            case RoleMemberType.MyKonicaMinoltaApplicationEntity:
                return ( 
                    <span><Badge bg="success">MKM</Badge> Application Entity</span>
                )
                
            case RoleMemberType.MyKonicaMinoltaApplicationGroup:
                return ( 
                    <span><Badge bg="success">MKM</Badge> Application Group</span>
                )

            case RoleMemberType.MyKonicaMinoltaJobCode:
                return ( 
                    <span><Badge bg="success">MKM</Badge> Job Code</span>
                )

            case RoleMemberType.MyKonicaMinoltaPerson:
                return ( 
                    <span><Badge bg="success">MKM</Badge> Person</span>
                )

            case RoleMemberType.MyKmbsPerson:
                return (
                    <span><Badge bg="danger">MyKMBS</Badge> Person</span>
                )

            default:
                return (
                    <span>Unrecognized Type: {this.props.type}</span>
                )
        }
    }

}

export default RoleMemberTypeDisplay;