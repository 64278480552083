const stripTags = (str: string) => {
    if (!str.match(/<[^>]+>/)) return str;
    // strip html tags
    return str.replace(/<[^>]+>/g, '');
}

const concatForCell = (str: string, range: number) => {
    str = str.trim();
    if (str.length <= range) return str;
    // concatenate string
    return str.substring(0, range) + "...";
}

const ConcatenatedString = ({ description, range }: { description: string, range: number }): JSX.Element => {
    description = stripTags(description);
    description = concatForCell(description, range);
    return <div>{description}</div>
};

export default ConcatenatedString;
