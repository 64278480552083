import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as ApiStore from '../../store/Api';
import * as RoleStore from '../../store/Role';
import { RoleMemberType } from '../../types/Role/RoleMemberType';
import RoleMemberAsyncTypeahead from './RoleMemberAsyncTypeahead';
import { CreateMember } from '../../types/Role/CreateMember';
import { EnumValues } from 'enum-values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/pro-solid-svg-icons' 
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import RoleMemberTable from './RoleMemberTable';
import { RoleMember } from '../../types/Role/RoleMember';
import { User } from 'oidc-client';
import { ROLE_FULL_ADMINISTRATOR } from '../../appRoles';
import { RoleMemberTypeDisplayName } from '../../types/Role/RoleMemberTypeDisplayName';

interface RoleMemberTypeDescriptor {
    value: RoleMemberType;
}

type RoleEditPageProps = {
    user: User;
    apiState: ApiStore.ApiState;
    roleState: RoleStore.RoleState;
    actions: typeof ApiStore.actionCreators
        & typeof RoleStore.actionCreators
}
    & RouteComponentProps<{ id: string }>;

interface RoleEditPageState {
    isLoaded: boolean;
    showModal: boolean;
    showModalError: boolean;
    isTypeAheadValid: boolean;
    isTypeAheadInvalid: boolean;
    validated: boolean;
    createMember: CreateMember;
}

const defaultRoleMemberType: RoleMemberType = RoleMemberType.MyKonicaMinoltaPerson;

class RoleEdit extends React.Component<RoleEditPageProps, RoleEditPageState> {

    constructor(props: RoleEditPageProps) {
        super(props);
        this.state = {
            isLoaded: false,
            showModal: false,
            showModalError: false,
            isTypeAheadValid: false,
            isTypeAheadInvalid: false,
            validated: false,
            createMember: {
                type: defaultRoleMemberType,
                id: undefined
            }
        };
    }
    
    public componentDidMount() {
        this.props.actions.unloadRoleDetails();
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
        
        if(!this.state.isLoaded && this.props.roleState.isLoadedDetails){
            this.setState({
                isLoaded: true,
            });
        }
    }

    private ensureDataFetched() {
        this.props.actions.requestRoleDetails(this.props.match.params.id);
        this.props.actions.requestApiSummary();
    }
    
    private setRoleMemberType(memberType: RoleMemberTypeDescriptor){
        this.setState({
            createMember: {
                ...this.state.createMember,
                type: memberType.value
            }
        });
    }

    private roleMemberSelectionCallback = (roleMember: RoleMember | undefined) => {
        this.setTypeAheadValidity(roleMember);
    }

    private setTypeAheadValidity(roleMember: RoleMember | undefined){
        if(roleMember === undefined){
            this.setState(
                {
                    isTypeAheadValid: false,
                    isTypeAheadInvalid: true,
                    createMember: {
                        ...this.state.createMember,
                        id: undefined
                    }
                }
            );
        }
        else{
            this.setState(
                {
                    isTypeAheadValid: true,
                    isTypeAheadInvalid: false,
                    createMember: {
                        type: roleMember.type,
                        id: roleMember.id
                    }
                }
            );
        }
    }

    private handleOpen(){
        if(this.props.roleState.isCreatingMemberError){
            this.props.actions.dismissMemberCreateError();
        }

        this.setState({
            showModal: true
        });
    }
    
    private handleClose(){
        this.setState({
            showModal: false
        });
    }

    private handleCloseCreateAlert(){
        this.props.actions.dismissMemberCreateError();
    }

    private handleCloseModalAlert(){
        this.setState({
            showModalError: false
        });
    }

    private handleSubmitModal = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        if(form.checkValidity()){
            if(this.state.createMember.type === undefined || this.state.createMember.id === undefined){
                this.setState({showModalError: true});
            }
            else{

                this.props.actions.submitMember(this.props.match.params.id, this.state.createMember);
                this.setState({
                    showModalError: false,
                    createMember: {
                        ...this.state.createMember,
                        id: undefined,
                    }
                });
                this.handleClose();
            }
        }

        this.setState({validated: true});
    }
    
    public render() {
        const roleDetails = this.props.roleState.roleDetails;
        const apiResource = this.props.apiState.apiResourceSummary.find(x => x.id === roleDetails.apiResourceId);
        const userRoles: string[] = this.props.user?.profile?.role ? this.props.user.profile.role : [];

        return (
            <React.Fragment>
                {!this.props.roleState.roleDetails
                ?
                <Alert variant="danger" className="text-center">
                    <strong>An error occurred while trying to retrieve the requested Role. Please ensure you are using a valid Role id.</strong>
                </Alert>
                :
                <Row>
                    <Col>
                        <Card className="panel">
                            <Card.Header>
                                <Row>
                                <Col md={6}>
                                    <h3>Edit Role</h3>
                                </Col>
                                <Col md={6} className="text-right">
                                    <Button variant="secondary" as={Link as any} to={`/roles`}>
                                        <FontAwesomeIcon className="back-btn" icon={faReply} />
                                    </Button>
                                </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={{ span: 8, offset: 2 }}>
                                        <Card>
                                            <Card.Body>
                                                {
                                                    this.props.roleState.isLoadedDetails && 
                                                    this.props.apiState.isLoaded &&
                                                    this.state.isLoaded
                                                ?
                                                <React.Fragment>
                                                    <Form>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm="3">API</Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control 
                                                                    type="text" 
                                                                    readOnly
                                                                    value={apiResource?.displayName}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm="3">Role ID</Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control 
                                                                    type="text" 
                                                                    readOnly
                                                                    value={roleDetails.id}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mt-4">
                                                            <Form.Label column sm="3">Display Name</Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control 
                                                                    type="text"
                                                                    readOnly
                                                                    value={roleDetails.displayName}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mt-4">
                                                            <Form.Label column sm="3">Description</Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control 
                                                                    as="textarea" 
                                                                    readOnly
                                                                    rows={3} 
                                                                    value={roleDetails.description}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Form>
                                                    <h4 className="mt-5">Members</h4>
                                                    <hr/>
                                                    { userRoles.includes(ROLE_FULL_ADMINISTRATOR) &&
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col md={12} className="text-right">
                                                                <Button variant="secondary" onClick={() => this.handleOpen()}>Add Member</Button>    
                                                            </Col>
                                                        </Row>
                                                        {this.props.roleState.isCreatingMemberError &&
                                                        <Row>
                                                            <Col md={12} className="mt-3">
                                                                <Alert variant="danger" className="text-center" onClose={() => this.handleCloseCreateAlert()} dismissible>
                                                                    <strong>An error occurred, Member could not be added. Please ensure Member is unique to Role.</strong>
                                                                </Alert>
                                                            </Col>
                                                        </Row>
                                                        }
                                                    </React.Fragment>
                                                    }
                                                    <Row className="mt-5">
                                                        <Col md={12}>
                                                            <RoleMemberTable roleId={this.props.match.params.id} {...this.props} />
                                                        </Col>
                                                    </Row>
                                                    <Modal size="lg" show={this.state.showModal} onHide={() => this.handleClose()}>
                                                        <Form 
                                                            noValidate 
                                                            validated={this.state.validated} 
                                                            onSubmit={(e: React.FormEvent<any>) => this.handleSubmitModal(e)}
                                                        >
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Add Member</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                {(this.state.showModalError) &&
                                                                <Alert variant="danger" className="text-center" onClose={() => this.handleCloseModalAlert()} dismissible>
                                                                    <strong>All fields required.</strong>
                                                                </Alert>
                                                                }
                                                                <Form.Group as={Row}>
                                                                    <Form.Label column sm="3">Member Type</Form.Label>
                                                                    <Col sm="9">
                                                                        <Select<RoleMemberTypeDescriptor>
                                                                            isSearchable={false}
                                                                            isClearable={false}
                                                                            options={EnumValues.getValues(RoleMemberType).map(function(value) { return { value: value as RoleMemberType }})}
                                                                            defaultValue={{ value: defaultRoleMemberType }}
                                                                            getOptionLabel={(option) => {
                                                                                return RoleMemberTypeDisplayName[option.value];
                                                                            }} 
                                                                            getOptionValue={(option) => {
                                                                                return option.value.toString();
                                                                            }} 
                                                                            placeholder="Select a role member type..."
                                                                            onChange={(selectedOption) => {
                                                                                if (Array.isArray(selectedOption)) {
                                                                                    throw new Error("Unexpected type passed to ReactSelect onChange handler");
                                                                                }
                                                                                if(selectedOption !== undefined && selectedOption !== null) {
                                                                                    this.setRoleMemberType(selectedOption);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group as={Row} controlId="request-person" className="async-typeahead">
                                                                    <Form.Label column sm="3">Member Name</Form.Label>
                                                                    <Col sm="9">
                                                                        <RoleMemberAsyncTypeahead
                                                                            roleMemberSelectionCallback={this.roleMemberSelectionCallback}
                                                                            memberType={this.state.createMember.type}
                                                                            isValid={this.state.isTypeAheadValid}
                                                                            isInvalid={this.state.isTypeAheadInvalid}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="primary" type="submit">
                                                                    Add Member
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Form>
                                                    </Modal>
                                                </React.Fragment>
                                                :
                                                <div className="d-flex justify-content-center mt-3">
                                                    <Spinner animation="border" variant="primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                }
            </React.Fragment>
        );
    }

}

function mapStateToProps(state: AppState) {
    return {
        user: state.oidc.user,
        apiState: state.api,
        roleState: state.role,
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign(
                {}, 
                ApiStore.actionCreators,
                RoleStore.actionCreators,
                ), dispatch )
        }
    }
)(RoleEdit as any);
