import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as SessionStore from '../../store/Session';
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import { SessionInfo } from '../../types/Session/SessionInfo';
import WorkItemsTable from './Requests/WorkItemsTable';
import RolesTable from './Roles/RolesTable';
import { Container, Nav, TabContent } from 'react-bootstrap';
import _ from 'lodash';

export interface OptionType {
    label: string;
    value: string;
}

type ProfilePageProps = {
    pictureUrl?: string;
    sessionState: SessionStore.SessionState;
    actions: typeof SessionStore.actionCreators;
}

interface ProfilePageState {
    time: number;
    showTimeZoneSelector: boolean;
    sessionLoaded: boolean;
    session: SessionInfo;
}

const tab = "requests";

class Profile extends React.Component<ProfilePageProps, ProfilePageState> {

    constructor(props: ProfilePageProps) {
        super(props);
        this.state = {
            time: 0,
            showTimeZoneSelector: false,
            sessionLoaded: false,
            session: {
                principalId: "",
                employeeId: "",
                firstName: "",
                preferredFirstName: null,
                lastName: "",
                fullName: "",
                emailAddress: null,
                jobTitle: "",
                jobCode: "",
                timeZone: "",
                roles: []
            }
        };
    }

    _timeZoneOptions = moment.tz.names().map((timeZone) => {
        return {
            value: timeZone,
            label: timeZone
        }
    });

    private interval: NodeJS.Timer | undefined;

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
        this.ensureDataFetched();
    }

    componentWillUnmount() {
        clearInterval?.(this.interval);
    }

    public componentDidUpdate() {
        this.ensureDataFetched();

        if (!this.state.sessionLoaded && this.props.sessionState.isLoaded) {
            this.setState({
                sessionLoaded: true,
                session: this.props.sessionState.session,
            });
        }
    }

    private ensureDataFetched() {
        this.props.actions.requestSession();
    }

    shouldComponentUpdate(_nextProps: ProfilePageProps): boolean {
        return !this.state.sessionLoaded
    }

    public render() {
        let mailtoEmailAddressLink = `mailto:${this.state.session.emailAddress}`;
        return (
            <Container className="profile" fluid>
                <h3 className="mb-3">User Profile</h3>
                <Row>
                    <Col xs={3}>
                        <Card className="text-center mt-2" body>
                            {this.props.pictureUrl !== undefined
                                ?
                                <div className="user-img" style={{ backgroundImage: `url("${this.props.pictureUrl}")` }} />
                                :
                                <div className="user-img-placeholder">
                                    <FontAwesomeIcon icon={faUser} />
                                </div>
                            }
                            {!this.props.sessionState.isLoaded
                                ?
                                <React.Fragment>
                                    <Row className="d-flex justify-content-center mt-2">
                                        <Col className='d-flex justify-content-center'>
                                            <Spinner animation="border" variant="primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Row className="mt-2">
                                        <Col className='d-flex justify-content-center fw-bold'>
                                            {`${this.state.session.firstName}${this.state.session.preferredFirstName ? ' "' + this.state.session.preferredFirstName + '"' : ''} ${this.state.session.lastName}`}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            {this.state.session.jobTitle} ({this.state.session.jobCode})
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            {this.state.session.emailAddress &&
                                                <a href={mailtoEmailAddressLink}>{this.state.session.emailAddress}</a>
                                            }
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            }

                        </Card>
                    </Col>
                    <Col xs={9}>
                        <Card>
                            <Tab.Container id="left-tabs-example" defaultActiveKey={tab}>
                                <Card.Header>
                                    <Nav variant="tabs" defaultActiveKey={tab}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="requests">Requests</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="roles">Roles</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Card.Header>
                                <Card.Body>
                                    <TabContent>
                                        <Tab.Pane eventKey="requests">
                                            {!this.state.sessionLoaded
                                                ?
                                                <React.Fragment>
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <Spinner animation="border" variant="primary" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <WorkItemsTable />
                                            }

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="roles">
                                            {!this.state.sessionLoaded
                                                ?
                                                <React.Fragment>
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <Spinner animation="border" variant="primary" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <RolesTable roles={this.state.session.roles} />
                                            }
                                        </Tab.Pane>
                                    </TabContent>
                                </Card.Body>
                            </Tab.Container>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

}

function mapStateToProps(state: AppState) {
    return {
        pictureUrl: state.oidc.user?.profile['picture'],
        sessionState: state.session
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, SessionStore.actionCreators), dispatch)
        }
    }
)(Profile as any);
