import * as React from 'react';
import { connect } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { AppState } from '../../store';
import { User } from 'oidc-client';
import { Redirect, Route } from 'react-router';
import { userManager } from '../../store/configureStore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdBadge, faUser } from "@fortawesome/pro-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import {
    AUTHENTICATION_PROVIDER_MY_KMBS,
    AUTHENTICATION_PROVIDER_MY_KONICA_MINOLTA
} from "../../authenticationProviders";

type LoginProps = {
    location: any;
    user: User;
}

class LoginPage extends React.Component<LoginProps> {

    public componentDidMount() {
        if(!this.props.location.search){
            userManager.signinRedirect({
                data: { path: '/' },
            });
        }
    }

    externalChallenge = (provider: string) => {
        // pass the current path to redirect to the correct page after successful login
        const returnUrl = this.getReturnUrl();
        if (returnUrl != null) {
            window.location.href = `./api/externalChallenge?provider=${provider}&returnUrl=${encodeURIComponent(returnUrl)}`;
        }
    };

    private getReturnUrl(){
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === 'ReturnUrl') {
                return decodeURIComponent(pair[1]);
            }
        }
    }
    public render() {

        // Redirect to home if we're logged in already
        if (this.props.user && !this.props.user.expired) {
            const renderComponent = () => (<Redirect to={{pathname: "/"}}/>);
            return <Route {...this.props} component={renderComponent} render={undefined}/>;
        }

        return (
            <Container fluid>
                <Row>
                    <Col md={6} className="pane">
                        <Row className="h-100">
                            <Col xl={{ span: 8, offset: 2 }} lg={{ span: 10, offset: 1 }} className="my-auto">
                                <Card>
                                    <Card.Body className="text-center">
                                        <Card.Title>ATS Service Automation Framework</Card.Title>
                                        <Card.Text className="welcome-text">
                                            <p>Welcome!</p>
                                            <p>Please select how you would like to log in.</p>
                                        </Card.Text>
                                        <Card.Text>
                                            <Col xl={{ span: 8, offset: 2}} lg={{ span: 10, offset: 1 }} className="d-grid gap-2 text-center align-items-center">
                                                <InputGroup size="lg" className="flex-nowrap mx-auto">
                                                    <span className="input-group-text">
                                                        <FontAwesomeIcon icon={faIdBadge} />                                                        
                                                    </span>
                                                    <Button variant="primary" className="form-control" onClick={() => this.externalChallenge(AUTHENTICATION_PROVIDER_MY_KONICA_MINOLTA)}>
                                                        Employee Login
                                                    </Button>
                                                </InputGroup>
                                                <Accordion flush={true} className="mt-3">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            <small className="text-muted">Other Login Types</small>
                                                        </Accordion.Header>
                                                        <Accordion.Body >
                                                            <InputGroup size="lg" className="flex-nowrap">
                                                                <span className="input-group-text">
                                                                    <FontAwesomeIcon icon={faUser} />                                                      
                                                                </span>
                                                                <Button variant="primary" className="form-control" onClick={() => this.externalChallenge(AUTHENTICATION_PROVIDER_MY_KMBS)}>
                                                                    Customer Login
                                                                </Button>
                                                            </InputGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} className="pane d-none d-md-block"></Col>
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state: AppState) {
    return {
        location: state.router.location,
        user: state.oidc.user,
    };
}

export default connect(
    mapStateToProps,   
)(LoginPage as any);
