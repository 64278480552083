import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as NavigationSummaryStore from '../../store/NavigationSummary';
import { NavCategorySummaryDto } from '../../types/Navigation/NavCategorySummaryDto';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Spinner from 'react-bootstrap/Spinner';

type NavigationTableProps = {
    navigationSummaryState: NavigationSummaryStore.NavigationSummaryState;
    actions: typeof NavigationSummaryStore.actionCreators;
}

interface NavigationTableState {
    isLoaded: boolean;
    refreshData: boolean;
    navigationSummary: NavCategorySummaryDto[];
}

class NavigationTable extends React.Component<NavigationTableProps, NavigationTableState> {
    public constructor(props: NavigationTableProps) {
        super(props);
        this.state = {
            isLoaded: false,
            refreshData: false,
            navigationSummary: []
        };
    }

    _pageSize = 50;
    _timeZone: string = "Universal";

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.actions.requestNavigationSummary();
    }

    render() {
        
        const NoDataComponent: React.FunctionComponent = (): React.ReactElement => {
            if(this.state.isLoaded){
                return (
                    <div className="d-flex justify-content-center mt-3" data-cy="bootstrap-table-loaded">
                        <p className="text-center">No navigation categories were found.</p>
                    </div>
                );
            }
            else{
                return (
                    <div className="d-flex justify-content-center mt-3">
                        <Spinner animation="border" variant="primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                );
            }
        };

        const { SearchBar } = Search;

        const columns = [
            {
                dataField: 'navigationPath',
                text: 'Navigation Path',
                sort: true,
                editable: false,
            },
            {
                dataField: 'clientName',
                text: 'Client',
                sort: true,
                editable: false,
                formatter: (clientName: string, row: NavCategorySummaryDto) => {
                    return (
                        <React.Fragment>
                            {clientName}<br/>
                            <small className="text-muted">{row.clientId}</small>
                        </React.Fragment>
                    )
                }
            },
            {
                dataField: 'clientUri',
                text: 'Client URI',
                sort: true,
                editable: false,
            }
        ];


        return (
            <React.Fragment>
                <ToolkitProvider
                    keyField="clientId"
                    search
                    data={ this.props.navigationSummaryState.navigationSummary } 
                    columns={ columns } 
                    >
                    {
                        props => (
                        <div>
                            <SearchBar { ...props.searchProps } />
                            <BootstrapTable
                                { ...props.baseProps }
                                striped
                                bordered={false}
                                defaultSorted={ [{ dataField: 'navigationPath', order: 'asc' }] }
                                pagination={ paginationFactory({sizePerPage: 25}) }
                                noDataIndication={ () => <NoDataComponent /> }
                            />
                        </div>
                        )
                    }
                </ToolkitProvider>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: AppState) {
    return {
        navigationSummaryState: state.navigationSummary
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, NavigationSummaryStore.actionCreators), dispatch )
        }
    }
)(NavigationTable);