import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { DashboardEntryDto } from '../types/Dashboard/DashboardEntryDto';
import { DashboardActionTypes, RECEIVE_DASHBOARD, REQUEST_DASHBOARD } from '../types/actions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DashboardState {
    isLoading: boolean;
    isLoaded: boolean;
    dashboardEntries: DashboardEntryDto[];
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestDashboard: (): AppThunkAction<DashboardActionTypes> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState 
            && appState.dashboard !== undefined 
            && !appState.dashboard.isLoading 
            && !appState.dashboard.isLoaded) 
        {

            dispatch({ type: REQUEST_DASHBOARD });

            axios
                .request<DashboardEntryDto[]>({
                    url: './api/dashboards'
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_DASHBOARD, dashboardEntries: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
};

export const reducer: Reducer<DashboardState> = (state: DashboardState | undefined, incomingAction: Action): DashboardState => {
    if (state === undefined) {
        return {
            isLoading: false, 
            isLoaded: false,
            dashboardEntries: []
        }
    }

    const action = incomingAction as DashboardActionTypes;
    switch (action.type) {
        case REQUEST_DASHBOARD:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        case RECEIVE_DASHBOARD:
            return {
                ...state,
                dashboardEntries: action.dashboardEntries,
                isLoading: false,
                isLoaded: true,
            };
    }

    return state;
};
