import { Col, Modal, Row, Stack } from "react-bootstrap";
import { IWorkItemDto } from "./types/IWorkItemDto";
import FormattedEventDateTime from "../../Shared/FormattedEventDateTime";
import DOMPurify from 'dompurify';
import { faBug, faTools } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IWorkItemDetailProps {
    show: boolean;
    handleClose: () => void;
    workItem?: IWorkItemDto
}

const sanitizeDescription = (str: string) => {
    return DOMPurify.sanitize(str, { USE_PROFILES: { html: true } });
}

const isHtml = (str: string) => {
    if (str.match(/<[^>]+>/)) return true;
}

const WorkItemDetail = ({ show, handleClose, workItem }: IWorkItemDetailProps): JSX.Element => {

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Work Item Detail
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="fw-bold">
                        Work Id:
                    </Col>
                    <Col xs={10}>
                        {workItem?.projectWorkItemId || "Pending"}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="fw-bold">
                        Title:
                    </Col>
                    <Col xs={10}>
                        {workItem?.title}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="fw-bold">
                        Subject:
                    </Col>
                    <Col xs={10}>
                        {workItem?.subjectName}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="fw-bold">
                        Description:
                    </Col>
                    <Col xs={10}>
                        {workItem?.description && isHtml(workItem?.description) &&
                            <div dangerouslySetInnerHTML={{ __html: sanitizeDescription(workItem?.description) }} />
                        }
                        {workItem?.description && !isHtml(workItem?.description) &&
                            <div>{workItem?.description}</div>
                        }

                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="fw-bold">
                        State:
                    </Col>
                    <Col xs={10}>
                        {workItem?.state}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="fw-bold">
                        Type:
                    </Col>
                    <Col xs={10}>
                        {workItem?.type === "Bug" ?
                            <Stack direction="horizontal" gap={1}>
                                <div><FontAwesomeIcon icon={faBug} className="text-danger" size="sm" /></div>
                                <div>{workItem?.type}</div>
                            </Stack> :
                            <Stack direction="horizontal" gap={1}>
                                <div><FontAwesomeIcon icon={faTools} className="text-primary" size="sm" /></div>
                                <div>{workItem?.type}</div>
                            </Stack>
                        }
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="fw-bold">
                        Submitted:
                    </Col>
                    <Col xs={10}>
                        {workItem?.submittedDateUtc &&
                            <FormattedEventDateTime date={workItem?.submittedDateUtc} />
                        }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )

}

export default WorkItemDetail;