import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { ApplicationActionTypes, REQUEST_APPLICATION_SUMMARY, RECEIVE_APPLICATION_SUMMARY, RECEIVE_APPLICATION_DETAILS, REQUEST_APPLICATION_DETAILS, UNLOAD_APPLICATION_DETAILS } from '../types/actions';
import { Client } from '../types/Application/Client';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ApplicationState {
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingDetails: boolean;
    isLoadedDetails: boolean;
    clientSummary: Client[];
    clientDetails: Client;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    requestApplicationSummary: (): AppThunkAction<ApplicationActionTypes> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState 
            && appState.application !== undefined 
            && !appState.application.isLoading
            && !appState.application.isLoaded) 
        {
            dispatch({ type: REQUEST_APPLICATION_SUMMARY });

            axios
                .request<Client[]>({
                    url: './api/applications'
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_APPLICATION_SUMMARY, clientSummary: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    
    requestApplicationDetails: (id: string): AppThunkAction<ApplicationActionTypes> => (dispatch, getState) => {

        const appState = getState();

        if (appState 
            && appState.application !== undefined 
            && !appState.application.isLoadingDetails
            && !appState.application.isLoadedDetails) 
        {
            dispatch({ type: REQUEST_APPLICATION_DETAILS });

            axios
                .request<Client>({
                    url: `./api/application/${id}`
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_APPLICATION_DETAILS, client: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    
    unloadApplicationDetails: (): AppThunkAction<ApplicationActionTypes> => (dispatch) => {

        dispatch({ type: UNLOAD_APPLICATION_DETAILS });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const emptyDetails = {
    id: 0,
    clientId: '',
    clientName: '',
    clientUri: '',
    logoUri: '',
    navigationPath: '',
    description: '',
    enabled: true,
    allowOfflineAccess: true,
    requireClientSecret: true,
    allowAccessTokensViaBrowser: true,
    requireConsent: true,
    created: '',
    redirectUris: [],
    allowedGrantTypes: [],
    postLogoutRedirectUris: [],
    allowedCorsOrigins: [],
    allowedScopes: [],
    claims: [],
}

export const reducer: Reducer<ApplicationState> = (state: ApplicationState | undefined, incomingAction: Action): ApplicationState => {
    if (state === undefined) {
        return {
            isLoading: false,
            isLoaded: false,
            isLoadingDetails: false,
            isLoadedDetails: false,
            clientSummary: [],
            clientDetails: emptyDetails
        };
    }

    const action = incomingAction as ApplicationActionTypes;
    switch (action.type) {
        case REQUEST_APPLICATION_SUMMARY:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            };
        case RECEIVE_APPLICATION_SUMMARY:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                clientSummary: action.clientSummary,
            };
        case REQUEST_APPLICATION_DETAILS:
            return {
                ...state,
                isLoadingDetails: true,
                isLoadedDetails: false,
            };
        case RECEIVE_APPLICATION_DETAILS:
            return {
                ...state,
                isLoadingDetails: false,
                isLoadedDetails: true,
                clientDetails: action.client,
            };
        case UNLOAD_APPLICATION_DETAILS:
            return {
                ...state,
                isLoadingDetails: false,
                isLoadedDetails: false,
                clientDetails: emptyDetails
            };
    }

    return state;
};
