import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as NavigationSummaryStore from '../../store/NavigationSummary'; 
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row';
import NavigationTable from './NavigationTable';

type NavigationPageProps = {
    navigationSummaryState: NavigationSummaryStore.NavigationSummaryState;
    actions: typeof NavigationSummaryStore.actionCreators;
}

interface NavigationPageState {
    isLoading: boolean;
}

class Navigation extends React.Component<NavigationPageProps, NavigationPageState> {

    constructor(props: NavigationPageProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.actions.requestNavigationSummary();
    }
    
    public render() {

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Card className="panel">
                            <Card.Header>
                                <Row>
                                    <Col md={12}>
                                        <h3>Navigation</h3>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <NavigationTable />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state: AppState) {
    return {
        navigationSummaryState: state.navigationSummary
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, NavigationSummaryStore.actionCreators), dispatch )
        }
    }
)(Navigation);
