import * as React from 'react';
import { Link } from 'react-router-dom';
import * as RoleStore from '../../store/Role';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import moment from 'moment-timezone'
import { RoleSummaryDto } from '../../types/Role/RoleSummaryDto';
import Spinner from 'react-bootstrap/Spinner';

type RoleTableProps = {
    apiResourceId: string | undefined;
    roleState: RoleStore.RoleState;
    actions: typeof RoleStore.actionCreators;
}

interface RoleTableState {
    showModal: boolean;
    deleteRole: RoleSummaryDto;
}

class RoleTable extends React.Component<RoleTableProps, RoleTableState> {
    public constructor(props: RoleTableProps) {
        super(props);
        this.state = {
            showModal: false,
            deleteRole: {
                id: '',
                displayName: '',
                description: '',
                createdDate: '',
                api: {
                    id: '',
                    enabled: true,
                    displayName: '',
                    description: '',
                    created: '',
                },
            }
        }
    }

    _pageSize = 50;
    _timeZone: string = "Universal";

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.actions.requestRoleSummary(this.props.apiResourceId);
    }

    render() {
        
        const NoDataComponent: React.FunctionComponent = (): React.ReactElement => {
            if(this.props.roleState.isLoaded){
                return (
                    <div className="d-flex justify-content-center mt-3" data-cy="bootstrap-table-loaded">
                        <p className="text-center">No roles were found.</p>
                    </div>
                );
            }
            else{
                return (
                    <div className="d-flex justify-content-center mt-3">
                        <Spinner animation="border" variant="primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                );
            }
        };

        const { SearchBar } = Search;

        let columns = [
            {
                dataField: 'api.displayName',
                text: 'API',
                sort: true,
                hidden: this.props.apiResourceId !== undefined,
                formatter: (apiDisplayName: string, row: RoleSummaryDto) => {
                    return (
                        <React.Fragment>
                            {apiDisplayName}<br/>
                            <small className="text-muted">{row.api.id}</small>
                        </React.Fragment>
                    )
                }
            },
            {
                dataField: 'displayName',
                text: 'Role',
                sort: true,
                formatter: (displayName: string, row: RoleSummaryDto) => {
                    return (
                        <React.Fragment>
                            {displayName}<br/>
                            <small className="text-muted">{row.id}</small>
                        </React.Fragment>
                    )
                }
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true
            },
            {
                dataField: 'createdDate',
                text: 'Created',
                sort: true,
                sortValue: (cell: any) => moment(cell).unix(),
                formatter: (cell: any) => {
                    let timestamp = moment(cell).tz(this._timeZone);

                    return (
                        <span>{timestamp.format("LLLL")}</span>
                    )
                }
            },
            {
                dataField: 'id',
                text: '',
                sort: false,
                align: 'right',
                headerStyle: () => {
                    return { width: '80px'};
                },
                formatter: (id: number, row: RoleSummaryDto) => {
                    return (
                        <React.Fragment>
                            <Link className="text-dark" to={`/roles/${id}`}>
                                <FontAwesomeIcon className="clickable mr-3" icon={faPencil} />
                            </Link>
                        </React.Fragment>
                    )
                }
            }
        ];

        // remove description column when viewed from /roles path
        if(window.location.pathname?.startsWith("/roles")){
            columns = columns.filter(el => el.dataField !== 'description'); 
        }

        return (
            <React.Fragment>
                <ToolkitProvider
                    keyField="id"
                    search
                    data={ this.props.roleState.roleSummary } 
                    columns={ columns } 
                    >
                    {
                        props => (
                        <div>
                            <SearchBar { ...props.searchProps } />
                            <BootstrapTable
                                { ...props.baseProps }
                                striped
                                bordered={false}
                                defaultSorted={ [ { dataField: 'api.displayName', order: 'asc' } ] }
                                pagination={ paginationFactory({sizePerPage: 25}) }
                                noDataIndication={ () => <NoDataComponent /> }
                            />
                        </div>
                        )
                    }
                </ToolkitProvider>
            </React.Fragment>
        );
    }
}

export default RoleTable;