export enum GrantTypes {
    Implicit = "implicit",
    Code = "authorization_code",
    Hybrid = "hybrid",
    ClientCredentials = "client_credentials",
    ResourceOwnerPassword = "password",
    DeviceFlow = "urn:ietf:params:oauth:grant-type:device_code",
    Delegation = "delegation"
}

export interface GrantType {
    value: string;
    label: string;
}