import _ from "lodash";
import { ISafTableSearchRequest } from "@konicaminolta/common-react";

export enum ApiRequestAction {
    UpdateSearchTerm,
    UpdateFilters,
    UpdatePagination,
    UpdateSorting
  }

export const apiRequestReducer = (state: ISafTableSearchRequest, action: { type: ApiRequestAction; value: ISafTableSearchRequest }) => {
    switch (action.type) {
      case ApiRequestAction.UpdateSearchTerm:
        if (state.searchTerm === action.value.searchTerm) {
          return state;
        }
        else {
          return {
            ...state,
            searchTerm: action.value.searchTerm
          };
        }
      case ApiRequestAction.UpdateFilters:
        if (_.isEqual(state.filters, action.value.filters)) {
          return state;
        }
        else {
          return {
            ...state,
            filters: action.value.filters
          };
        }
      case ApiRequestAction.UpdateSorting:
        if (_.isEqual(state.sort, action.value.sort)) {
          return state;
        }
        else {
          return {
            ...state,
            sort: action.value.sort
          };
        }
      case ApiRequestAction.UpdatePagination:
        if (state.take === action.value.take && state.skip === action.value.skip) {
          return state;
        }
        else {
          return {
            ...state,
            take: action.value.take,
            skip: action.value.skip
          };
        }
      default:
        return state;
    }
  };