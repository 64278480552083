import { Fragment, useMemo } from "react";
import { ISafTableColumnDef, ISafTableSortingState, SafTable } from "@konicaminolta/common-react";

interface RoleDefinition {
  name: string
}

const RolesTable = ({
  roles
}: {
  roles: string[]
}) => {
  const tableKey = "FS:RolesTable"



  const initialSortingState: ISafTableSortingState = [{
    id: "name",
    desc: true
  }]

  const roleDefinitions: RoleDefinition[] = useMemo(() => {
    return roles.map((name: string) => { return { name } })
  }, []);

  const columns: ISafTableColumnDef<RoleDefinition>[] = useMemo(() => {
    return [
      {
        accessorFn: row => row.name,
        accessorKey: "name",
        id: 'name',
        cell: info => info.getValue(),
        header: "Role Name",
        enableSorting: true
      },
    ];
  }, []);

  return (
    <Fragment>
      <SafTable
        tableKey={tableKey}
        bordered={false}
        hover={true}
        responsive={true}
        striped={true}
        isLoading={false}
        data={roleDefinitions}
        columns={columns}
        handleRowClick={(value: RoleDefinition) => console.log(value.name)}
        totalSize={roles.length}
        initialSortingState={initialSortingState}
      />
    </Fragment>
  )
};

export default RolesTable;