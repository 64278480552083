import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { NavigationSummaryActionTypes, REQUEST_NAVIGATION_SUMMARY, RECEIVE_NAVIGATION_SUMMARY } from '../types/actions';
import { NavCategorySummaryDto } from '../types/Navigation/NavCategorySummaryDto';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface NavigationSummaryState {
    isLoadingNavigationSummary: boolean;
    isLoadedNavigationSummary: boolean;
    navigationSummary: NavCategorySummaryDto[];
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    requestNavigationSummary: (): AppThunkAction<NavigationSummaryActionTypes> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState 
            && appState.navigationSummary !== undefined 
            && !appState.navigationSummary.isLoadingNavigationSummary
            && !appState.navigationSummary.isLoadedNavigationSummary) 
        {
            dispatch({ type: REQUEST_NAVIGATION_SUMMARY });

            axios
                .request<NavCategorySummaryDto[]>({
                    url: './api/nav/categories'
                })
                .then((response) => {
                    const { data } = response;
                    dispatch({ type: RECEIVE_NAVIGATION_SUMMARY, navigationSummary: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<NavigationSummaryState> = (state: NavigationSummaryState | undefined, incomingAction: Action): NavigationSummaryState => {
    if (state === undefined) {
        return {
            isLoadingNavigationSummary: false,
            isLoadedNavigationSummary: false,
            navigationSummary: []
        };
    }

    const action = incomingAction as NavigationSummaryActionTypes;
    switch (action.type) {
        case REQUEST_NAVIGATION_SUMMARY:
            return {
                ...state,
                isLoadingNavigationSummary: true,
                isLoadedNavigationSummary: false
            };
        case RECEIVE_NAVIGATION_SUMMARY:
            return {
                ...state,
                isLoadingNavigationSummary: false,
                isLoadedNavigationSummary: true,
                navigationSummary: action.navigationSummary,
            };
    }

    return state;
};
