import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as ApiStore from '../../store/Api';
import * as RoleStore from '../../store/Role';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row';
import RoleTable from './RoleTable';

type RolePageProps = {
    apiState: ApiStore.ApiState;
    roleState: RoleStore.RoleState;
    actions: typeof ApiStore.actionCreators
        & typeof RoleStore.actionCreators
}

interface RolePageState {
    isLoading: boolean;
}

class Role extends React.Component<RolePageProps, RolePageState> {

    constructor(props: RolePageProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    public componentDidMount() {
        this.props.actions.unloadRoleSummary();
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.actions.requestApiSummary();
    }

    public render() {

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Card className="panel">
                            <Card.Header>
                                <Row>
                                    <Col md={12}>
                                        <h3>Roles</h3>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <RoleTable apiResourceId={undefined} {...this.props} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state: AppState) {
    return {
        roleState: state.role,
        apiState: state.api,
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign(
                {}, 
                ApiStore.actionCreators,
                RoleStore.actionCreators,
            ), dispatch )
        }
    }
)(Role as any);
