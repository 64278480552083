import { CALL_HISTORY_METHOD } from "connected-react-router";
import { SessionInfo } from "./Session/SessionInfo";
import { NavCategorySummaryDto } from "./Navigation/NavCategorySummaryDto";
import { Client } from "./Application/Client";
import { ApiResource } from "./Api/ApiResource";
import { RoleSummaryDto } from "./Role/RoleSummaryDto";
import { RoleDetailsDto } from "./Role/RoleDetailsDto";
import { RoleMember } from "./Role/RoleMember";
import { DashboardEntryDto } from "./Dashboard/DashboardEntryDto";
import { CommonAppActionTypes } from "@konicaminolta/common-react";
import { NavigationActionTypes } from "@konicaminolta/common-react/dist/konicaminolta-common-react/actions/NavigationActionTypes";
import { ErrorActionTypes } from "./ErrorActionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// History
export interface CallHistoryMethod {
    type: typeof CALL_HISTORY_METHOD,
    payload: {
      method: string,
      args: any[]
    }
}

// Session
export const REQUEST_SESSION = "REQUEST_SESSION";
export const RECEIVE_SESSION = "RECEIVE_SESSION";
export const SUBMIT_UPDATE_TIME_ZONE = "SUBMIT_UPDATE_TIME_ZONE";
export const RECEIVE_UPDATE_TIME_ZONE_RESPONSE = "RECEIVE_UPDATE_TIME_ZONE_RESPONSE";

export interface RequestSessionAction {
    type: typeof REQUEST_SESSION;
}

export interface ReceiveSessionAction {
    type: typeof RECEIVE_SESSION;
    session: SessionInfo;
}

export interface SubmitUpdateTimeZoneAction {
    type: typeof SUBMIT_UPDATE_TIME_ZONE;
}

export interface ReceiveUpdateTimeZoneAction {
    type: typeof RECEIVE_UPDATE_TIME_ZONE_RESPONSE;
}

export type SessionActionTypes = 
    RequestSessionAction |
    ReceiveSessionAction |
    SubmitUpdateTimeZoneAction |
    ReceiveUpdateTimeZoneAction;

// Navigation
export const REQUEST_NAVIGATION_SUMMARY = "REQUEST_NAVIGATION_SUMMARY";
export const RECEIVE_NAVIGATION_SUMMARY = "RECEIVE_NAVIGATION_SUMMARY";

export interface RequestNavigationSummaryAction {
    type: typeof REQUEST_NAVIGATION_SUMMARY;
}

export interface ReceiveNavigationSummaryAction {
    type: typeof RECEIVE_NAVIGATION_SUMMARY;
    navigationSummary: NavCategorySummaryDto[];
}

export type NavigationSummaryActionTypes = 
    ErrorActionTypes |
    NavigationActionTypes |
    RequestNavigationSummaryAction |
    ReceiveNavigationSummaryAction;

// Application
export const REQUEST_APPLICATION_SUMMARY = "REQUEST_APPLICATION_SUMMARY";
export const RECEIVE_APPLICATION_SUMMARY = "RECEIVE_APPLICATION_SUMMARY";
export const REQUEST_APPLICATION_DETAILS = "REQUEST_APPLICATION_DETAILS";
export const RECEIVE_APPLICATION_DETAILS = "RECEIVE_APPLICATION_DETAILS";
export const UNLOAD_APPLICATION_DETAILS = "UNLOAD_APPLICATION_DETAILS";

export interface RequestApplicationSummaryAction {
    type: typeof REQUEST_APPLICATION_SUMMARY;
}

export interface ReceiveApplicationSummaryAction {
    type: typeof RECEIVE_APPLICATION_SUMMARY;
    clientSummary: Client[];
}

export interface RequestApplicationDetailsAction {
    type: typeof REQUEST_APPLICATION_DETAILS;
}

export interface ReceiveApplicationDetailsAction {
    type: typeof RECEIVE_APPLICATION_DETAILS;
    client: Client;
}

export interface UnloadApplicationDetailsAction {
    type: typeof UNLOAD_APPLICATION_DETAILS;
}

export type ApplicationActionTypes = 
    ErrorActionTypes |
    RequestApplicationSummaryAction |
    ReceiveApplicationSummaryAction |
    RequestApplicationDetailsAction |
    ReceiveApplicationDetailsAction |
    UnloadApplicationDetailsAction |
    CallHistoryMethod;

// API
export const REQUEST_API = "REQUEST_API";
export const RECEIVE_API = "RECEIVE_API";
export const REQUEST_API_DETAILS = "REQUEST_API_DETAILS";
export const RECEIVE_API_DETAILS = "RECEIVE_API_DETAILS";
export const UNLOAD_API_DETAILS = "UNLOAD_API_DETAILS";

export interface RequestApiAction {
    type: typeof REQUEST_API;
}

export interface ReceiveApiAction {
    type: typeof RECEIVE_API;
    apiResourceSummary: ApiResource[];
}

export interface RequestApiDetailsAction {
    type: typeof REQUEST_API_DETAILS;
}

export interface ReceiveApiDetailsAction {
    type: typeof RECEIVE_API_DETAILS;
    apiDetails: ApiResource;
}

export interface UnloadApiDetailsAction {
    type: typeof UNLOAD_API_DETAILS;
}

export type ApiActionTypes = 
    ErrorActionTypes |
    RequestApiAction |
    ReceiveApiAction |
    RequestApiDetailsAction |
    ReceiveApiDetailsAction |
    UnloadApiDetailsAction |
    CallHistoryMethod;

// Role
export const REQUEST_ROLE = "REQUEST_ROLE";
export const RECEIVE_ROLE = "RECEIVE_ROLE";
export const UNLOAD_ROLE = "UNLOAD_ROLE";
export const REQUEST_ROLE_DETAILS = "REQUEST_ROLE_DETAILS";
export const RECEIVE_ROLE_DETAILS = "RECEIVE_ROLE_DETAILS";
export const UNLOAD_ROLE_DETAILS = "UNLOAD_ROLE_DETAILS";
export const SUBMIT_CREATE_MEMBER = "SUBMIT_CREATE_MEMBER";
export const RECEIVE_CREATE_MEMBER_RESPONSE = "RECEIVE_CREATE_MEMBER_RESPONSE";
export const RECEIVE_CREATE_MEMBER_RESPONSE_ERROR = "RECEIVE_CREATE_MEMBER_RESPONSE_ERROR";
export const DISMISS_MEMBER_CREATE_ERROR = "DISMISS_MEMBER_CREATE_ERROR";
export const REQUEST_MEMBER_SUMMARY = "REQUEST_MEMBER_SUMMARY";
export const RECEIVE_MEMBER_SUMMARY = "RECEIVE_MEMBER_SUMMARY";
export const UNLOAD_ROLE_MEMBER_SUMMARY = "UNLOAD_ROLE_MEMBER_SUMMARY";
export const SUBMIT_DELETE_MEMBER = "SUBMIT_DELETE_MEMBER";
export const RECEIVE_DELETE_MEMBER_RESPONSE = "RECEIVE_DELETE_MEMBER_RESPONSE";
export const RECEIVE_DELETE_MEMBER_RESPONSE_ERROR = "RECEIVE_DELETE_MEMBER_RESPONSE_ERROR";
export const DISMISS_MEMBER_DELETE_ERROR = "DISMISS_MEMBER_DELETE_ERROR";

export interface RequestRoleAction {
    type: typeof REQUEST_ROLE;
}

export interface ReceiveRoleAction {
    type: typeof RECEIVE_ROLE;
    roleSummary: RoleSummaryDto[];
}

export interface UnloadRoleAction {
    type: typeof UNLOAD_ROLE;
}

export interface RequestRoleDetailsAction {
    type: typeof REQUEST_ROLE_DETAILS;
}

export interface ReceiveRoleDetailsAction {
    type: typeof RECEIVE_ROLE_DETAILS;
    roleDetails: RoleDetailsDto;
}

export interface UnloadRoleDetailsAction {
    type: typeof UNLOAD_ROLE_DETAILS;
}

export interface SubmitCreateMemberAction {
    type: typeof SUBMIT_CREATE_MEMBER;
}

export interface ReceiveCreateMemberResponseAction {
    type: typeof RECEIVE_CREATE_MEMBER_RESPONSE;
}

export interface ReceiveCreateMemberResponseErrorAction {
    type: typeof RECEIVE_CREATE_MEMBER_RESPONSE_ERROR;
}

export interface DismissMemberCreateErrorAction {
    type: typeof DISMISS_MEMBER_CREATE_ERROR;
}

export interface RequestMemberSummaryAction {
    type: typeof REQUEST_MEMBER_SUMMARY;
}

export interface ReceiveMemberSummaryAction {
    type: typeof RECEIVE_MEMBER_SUMMARY;
    roleMemberSummary: RoleMember[];
}

export interface UnloadRoleMemberSummaryAction {
    type: typeof UNLOAD_ROLE_MEMBER_SUMMARY;
}

export interface SubmitDeleteMemberAction {
    type: typeof SUBMIT_DELETE_MEMBER;
}

export interface ReceiveDeleteMemberResponseAction {
    type: typeof RECEIVE_DELETE_MEMBER_RESPONSE;
}

export interface ReceiveDeleteMemberResponseErrorAction {
    type: typeof RECEIVE_DELETE_MEMBER_RESPONSE_ERROR;
}

export interface DismissMemberDeleteErrorAction {
    type: typeof DISMISS_MEMBER_DELETE_ERROR;
}


export type RoleActionTypes = 
    ErrorActionTypes |
    RequestRoleAction |
    ReceiveRoleAction |
    UnloadRoleAction |
    RequestRoleDetailsAction |
    ReceiveRoleDetailsAction |
    UnloadRoleDetailsAction |
    SubmitCreateMemberAction |
    ReceiveCreateMemberResponseAction |
    ReceiveCreateMemberResponseErrorAction |
    DismissMemberCreateErrorAction | 
    RequestMemberSummaryAction |
    ReceiveMemberSummaryAction |
    UnloadRoleMemberSummaryAction |
    SubmitDeleteMemberAction |
    ReceiveDeleteMemberResponseAction |
    ReceiveDeleteMemberResponseErrorAction |
    DismissMemberDeleteErrorAction |
    CallHistoryMethod;

// Dashboard
export const REQUEST_DASHBOARD = "REQUEST_DASHBOARD";
export const RECEIVE_DASHBOARD = "RECEIVE_DASHBOARD";

export interface RequestDashboardAction {
    type: typeof REQUEST_DASHBOARD;
}

export interface ReceiveDashboardAction {
    type: typeof RECEIVE_DASHBOARD;
    dashboardEntries: DashboardEntryDto[];
}

export type DashboardActionTypes = 
    RequestDashboardAction |
    ReceiveDashboardAction;

// Aggregate of all actions
export type AppActions = 
    CommonAppActionTypes |
    SessionActionTypes |
    NavigationSummaryActionTypes |
    ApplicationActionTypes |
    ApiActionTypes |
    RoleActionTypes |
    DashboardActionTypes;
