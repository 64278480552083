import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as ApiStore from '../../store/Api';
import * as RoleStore from '../../store/Role';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/pro-solid-svg-icons' 
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import RoleTable from '../Role/RoleTable';
import ReactMarkdown from 'react-markdown';
import { AnyMxRecord } from 'dns';

type ApiEditPageProps = {
    apiState: ApiStore.ApiState;
    roleState: RoleStore.RoleState;
    actions: typeof ApiStore.actionCreators
        & typeof RoleStore.actionCreators;
}
    & RouteComponentProps<{ id: string }>;

interface ApiEditPageState {
    isLoaded: boolean;
}

class ApiEdit extends React.Component<ApiEditPageProps, ApiEditPageState> {

    constructor(props: ApiEditPageProps) {
        super(props);
        this.state = {
            isLoaded: false,
        };
    }
    
    public componentDidMount() {
        this.props.actions.unloadApiDetails();
        this.props.actions.unloadRoleSummary();
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
        
        if(!this.state.isLoaded && this.props.apiState.isLoadedDetails){
            this.setState({
                isLoaded: true,
            });
        }
    }

    private ensureDataFetched() {
        this.props.actions.requestApiDetails(this.props.match.params.id);
    }

    public render() {

        const api = this.props.apiState.apiDetails;

        return (
            <React.Fragment>
                {!this.props.apiState.apiDetails
                ?
                <Alert variant="danger" className="text-center">
                    <strong>An error occurred while trying to retrieve the requested API. Please ensure you are using a valid API id.</strong>
                </Alert>
                :
                <Row>
                    <Col>
                        <Card className="panel">
                            <Card.Header>
                                <Row>
                                <Col md={6}>
                                    <h3>Edit API</h3>
                                </Col>
                                <Col md={6} className="text-right">
                                    <Button variant="secondary" as={Link as any} to={`/api`}>
                                        <FontAwesomeIcon className="back-btn" icon={faReply} />
                                    </Button>
                                </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={{ span: 8, offset: 2 }}>
                                        <Card>
                                            <Card.Body>
                                                {this.props.apiState.isLoadedDetails && this.state.isLoaded
                                                ?
                                                <React.Fragment>
                                                    <Form>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm="3">ID</Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control 
                                                                    type="text" 
                                                                    minLength={1}
                                                                    readOnly
                                                                    value={api.id}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mt-4">
                                                            <Form.Label column sm="3">Display Name</Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control 
                                                                    type="text"
                                                                    required 
                                                                    minLength={1}
                                                                    readOnly
                                                                    value={api.displayName}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mt-4">
                                                            <Form.Label column sm="3">Description</Form.Label>
                                                            <Col sm="9">
                                                                <div className="fake-disabled-textarea">
                                                                    <ReactMarkdown 
                                                                        children={api.description}
                                                                        linkTarget="_blank"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mt-4">
                                                            <Form.Label column sm="3">Enabled</Form.Label>
                                                            <Col sm="9">
                                                                <Form.Check 
                                                                    type="switch"
                                                                    label=""
                                                                    id="api-enabled"
                                                                    disabled
                                                                    checked={api.enabled}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Form>
                                                    <h4 className="mt-5">Roles</h4>
                                                    <hr/>
                                                    <Row className="mt-5">
                                                        <Col md={12}>
                                                            <RoleTable apiResourceId={this.props.match.params.id} {...this.props} />
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                                :
                                                <div className="d-flex justify-content-center mt-3">
                                                    <Spinner animation="border" variant="primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                }
            </React.Fragment>
        );
    }

}

function mapStateToProps(state: AppState) {
    return {
        apiState: state.api,
        roleState: state.role,
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign(
                {}, 
                ApiStore.actionCreators,
                RoleStore.actionCreators,
            ), dispatch )
        }
    }
)(ApiEdit as any);
