import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import * as ApplicationStore from '../../store/Application';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row';
import ApplicationTable from './ApplicationTable';

type ApplicationPageProps = {
    applicationState: ApplicationStore.ApplicationState;
    actions: typeof ApplicationStore.actionCreators;
}

interface ApplicationPageState {
    isLoading: boolean;
}

class ApplicationPage extends React.Component<ApplicationPageProps, ApplicationPageState> {

    constructor(props: ApplicationPageProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }
    
    public render() {

        return (
            <React.Fragment>
                <Row className="application">
                    <Col>
                        <Card className="panel">
                            <Card.Header>
                                <Row>
                                    <Col md={12}>
                                        <h3>Applications</h3>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={4}>
                                        <Card className="application-description-card">
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <strong>
                                                            Applications represent clients that can request tokens 
                                                            from your identity server.
                                                        </strong>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col>
                                                        <span>
                                                            The details vary, but you typically define the following 
                                                            common settings for a client:
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col>
                                                        <ul>
                                                            <li>a unique client ID</li>
                                                            <li>a secret if needed</li>
                                                            <li>the allowed interactions with the token service (called a grant type)</li>
                                                            <li>a network location where identity and/or access token gets sent to (called a redirect URI)</li>
                                                            <li>a list of scopes (resources) the client is allowed to access</li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={8}>
                                        <ApplicationTable {...this.props}/>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state: AppState) {
    return {
        applicationState: state.application
    };
}

export default connect(
    mapStateToProps,
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, ApplicationStore.actionCreators), dispatch )
        }
    }
)(ApplicationPage as any);
